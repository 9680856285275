import React from 'react'
import {Box} from "@mui/material";
import CompareReportByRange from "../components/reports/compareReport/CompareReportByRange";

export default function CompareReportPage({pageHeight}:{pageHeight: number}) {

    return (
        <Box sx={{ width: '100%', typography: 'body1', mt:1 }}>
            <CompareReportByRange/>
        </Box>
    );
}
