import jwtDecode, {JwtPayload} from "jwt-decode";
import {Role} from "./role";


export interface ILoginCredentials {
    login: string;
    password: string;
}

export interface ITokenResponce {
    access_token: string
    refresh_token?: string
}

export type ICustomJwtPayloadDto = JwtPayload & {
    role: string,
    permissions: string[],
    userId: number,
    userName: string,
};

export type ICustomJwtPayload = JwtPayload & {
    role: string,
    permissions: string[],
    userId: number,
    userName: string,
};

export interface IAuthData {
    token: string | null
    isAuthenticated: boolean,
    role: Role,
    permissions: string[],
    userId: number,
    data: ICustomJwtPayload | undefined
}

export const initialAuthState: IAuthData = {
    token: null,
    isAuthenticated: false,
    role: 'GUEST',
    permissions: [],
    userId: 0,
    data: undefined,
};

export function authObjectCreator(token:string): IAuthData{
    const auth: IAuthData = {...initialAuthState}
    auth.token = token
    let payloadObject:ICustomJwtPayloadDto = jwtDecode<ICustomJwtPayloadDto>(token)
    if (payloadObject != null){
        auth.data = {...payloadObject}
        auth.role = payloadObject.role.replace('ROLE_', '') as Role
        auth.permissions = payloadObject.permissions
        auth.userId = payloadObject.userId
        auth.isAuthenticated = true
    }
    auth.isAuthenticated = true
    return auth
}