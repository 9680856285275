import React, {useState} from "react";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {AlertTitle, IconButton, Snackbar} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useAppDispatch, useAppSelector} from "../../app/hooks/appHooks";
import {clearMessage} from "../../app/reducers/message/globalMessageSlice";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert( props, ref ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function MessageSnackBar () {
    const { isPresence, messageType, title, message } = useAppSelector((state) => state.globalMessage)
    const dispatch = useAppDispatch()

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(clearMessage())
    };

    const action = (
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const showSnackbar = () => {
        switch (messageType) {
            case "error": return <Snackbar open={ isPresence } autoHideDuration={6000} onClose={handleClose} action={action} >
                                        <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
                                            <AlertTitle>{title}</AlertTitle>
                                            {message}
                                        </Alert>
                                </Snackbar>
            case "warning": return <Snackbar open={ isPresence } autoHideDuration={6000} onClose={handleClose} action={action} >
                                        <Alert onClose={handleClose} severity='warning' sx={{ width: '100%' }}>
                                            <AlertTitle>{title}</AlertTitle>
                                            {message}
                                        </Alert>
                                    </Snackbar>
            case "success": return <Snackbar open={ isPresence } autoHideDuration={6000} onClose={handleClose} action={action} >
                                        <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
                                            <AlertTitle>{title}</AlertTitle>
                                            {message}
                                        </Alert>
                                    </Snackbar>
            default: return null
        }
    }

    return(
        <div>
            { showSnackbar() }
        </div>
    )

}