import React from 'react'
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    TextField,
} from "@mui/material";
import CitySelect from "../components/cityes/CitySelect";
import Divider from "@mui/material/Divider";
import {useAppDispatch, useAppSelector} from "../app/hooks/appHooks";
import {SubmitHandler, useForm} from "react-hook-form";
import {IGlobalMessage} from "../app/model/message/IGlobalMessage";
import {setError, setOkMessage} from "../app/reducers/message/globalMessageSlice";
import {useMessageController} from "../app/controllers/useMessageController";

export default function Dashboard() {
    const {currentCity} = useAppSelector((state) => state.city.state)
    const dispatch = useAppDispatch()
    const {sendMessage} = useMessageController()
    const {
        register,
        handleSubmit,
        formState: {errors, defaultValues},
    } = useForm<IGlobalMessage>({
        defaultValues: {
            cityId: currentCity.id,
            message: ""
        }
    })


    const cityFilterHandler = (cityId: number) => {
    }

    const onSubmit: SubmitHandler<IGlobalMessage> = (message) => {
        message.cityId = currentCity.id
        sendMessage(message).then(value => {
            dispatch(setOkMessage({title:'Повідомлення надіслано.', message:'Для міста ' + currentCity.name}))
        }).catch(reason => {
            dispatch(setError({title:'Повідомлення не надіслано.', message:(reason.response?.data as any).message}))
        })
    }

    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', mt: 2}}>
                <CitySelect onCityChange={cityFilterHandler} filters={undefined}/>
            </Box>
            <Divider variant="middle" sx={{mt: 2, mb: 2, ml: 8, mr: 8}}/>

            <Box sx={{display: 'flex', flexDirection: 'column', width: "50%", ml: 3}}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate >
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: "end", width: "100%"}}>
                        <FormControl variant="outlined" sx={{m: 1, width: '100%'}}>
                            <TextField
                                label="Повідомлення всім водіям в місті"
                                multiline
                                {...register("message", {
                                    required: 'Потрібно ввести повідомлення. Мінімальна довжина 10 символів',
                                    minLength: 10
                                })}
                                error={!!errors.message}
                            />
                            <FormHelperText>
                                {errors.message?.message ? errors.message?.message : ''}
                            </FormHelperText>
                        </FormControl>

                        <Button variant="contained" sx={{width: "130px", mt: 2}} type="submit">
                            Відправити
                        </Button>
                    </Box>
                </form>

            </Box>

        </Box>
    );
}
