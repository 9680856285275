
const prod = {
    url: {
        LOGIN: '/api/v1/taxi/signin',
        FIN_REPORT: '/api/v1/taxi/report',
        ORDERS_REPORT: '/api/v1/taxi/orderReport',
        FRAUD_REPORT: '/api/v1/taxi/fraudReport',
        COMPARE_REPORT: '/api/v1/taxi/comparativeReport',
        CITY: '/api/v1/taxi/city',
        ORDER: '/api/v1/taxi/order/id',
        TOP_UP_CARD_OPERATION: '/api/v1/taxi/topupcards',
        TRANSFER_FUND_OPERATION: '/api/v1/taxi/transferFunds',
        DRIVERS: '/api/v1/taxi/drivers',
        DRIVER_CONTROL: '/api/v1/taxi/driverControl',
        MESSAGE: '/api/v1/taxi/message'
    }
};
const dev = {
    url: {
        LOGIN: 'http://localhost:8080/api/v1/taxi/signin',
        FIN_REPORT: 'http://localhost:8080/api/v1/taxi/report',
        ORDERS_REPORT: 'http://localhost:8080/api/v1/taxi/orderReport',
        FRAUD_REPORT: 'http://localhost:8080/api/v1/taxi/fraudReport',
        COMPARE_REPORT: 'http://localhost:8080/api/v1/taxi/comparativeReport',
        CITY: 'http://localhost:8080/api/v1/taxi/city',
        ORDER: 'http://localhost:8080/api/v1/taxi/order/id',
        TOP_UP_CARD_OPERATION: 'http://localhost:8080/api/v1/taxi/topupcards',
        TRANSFER_FUND_OPERATION: 'http://localhost:8080/api/v1/taxi/transferFunds',
        DRIVERS: 'http://localhost:8080/api/v1/taxi/drivers',
        DRIVER_CONTROL: 'http://localhost:8080/api/v1/taxi/driverControl',
        MESSAGE: 'http://localhost:8080/api/v1/taxi/message'
    }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
