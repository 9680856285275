import {config} from "../ApiConstants";
import {useAppDispatch} from "../hooks/appHooks";
import {AxiosError} from "axios/index";
import {setError} from "../reducers/message/globalMessageSlice";
import {IRequestQueryMultiple} from "../model/pageRequestQuery/IRequestQueryMultiple";
import {IFinReport, IFinReportPage} from "../model/finReport/IFinReport";
import axiosApi from "../axiosApi/axiosConfig";
import {IOrdersReport} from "../model/ordersReport/IOrdersReport";

export function useOrdersReportController(){
    const dispatch = useAppDispatch()

    async function getReportByDateRange(queryParams:IRequestQueryMultiple): Promise<IOrdersReport[]>{
        try{
            const response = await axiosApi.post<IOrdersReport[]>(config.url.ORDERS_REPORT + '/dataTimePeriod', queryParams)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання звіту.', message:(error.response?.data as any).message}))
            return []
        }
    }

    async function updateOrder(order:IOrdersReport): Promise<IOrdersReport | undefined>{
        try{
            const response = await axiosApi.patch<IOrdersReport>(config.url.ORDERS_REPORT, order)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка оновлення замовлення.', message:(error.response?.data as any).message}))
            return undefined
        }
    }


    return {
        getReportByDateRange,
        updateOrder
    }

}