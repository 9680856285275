
import axios from "axios";
import {config} from "../ApiConstants";

import {ILoginCredentials, ITokenResponce} from "../model/auth/IAuth";


export async function login(credentials: ILoginCredentials): Promise<string>{
    const response = await axios.post<ITokenResponce>(config.url.LOGIN,{
        login: credentials.login,
        password: credentials.password
    })
    const token = response.data.access_token;
    // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    localStorage.setItem('auth_token', token); // Сохраняем токен в localStorage
    return token
}

export async function logOut(): Promise<void>{
    // delete axios.defaults.headers.common['Authorization']
    localStorage.removeItem('auth_token'); // Удаляем токен из localStorage
}