import {config} from "../ApiConstants";
import axiosApi from "../axiosApi/axiosConfig";
import {IGlobalMessage} from "../model/message/IGlobalMessage";

export function useMessageController(){

    async function sendMessage(message:IGlobalMessage): Promise<void>{
        await axiosApi.post(config.url.MESSAGE + '/global', message)
    }


    return {
        sendMessage,
    }

}