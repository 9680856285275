import React  from 'react';
import {Box, FormControl, IconButton, InputLabel, Menu, MenuItem, MenuList, Select, Typography} from "@mui/material";
import AccountCircle from '@mui/icons-material/AccountCircle';
import {useAppDispatch, useAppSelector} from "../../app/hooks/appHooks";
import {SelectChangeEvent} from "@mui/material/Select";
import Divider from "@mui/material/Divider";

function UserIcon() {
    const { role, isAuthenticated, data } = useAppSelector(state => state.auth.state)
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const dispatcher = useAppDispatch()
    // const localization = useAppSelector(state => state.localization.local)
    // const { t } = useTranslation();


    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getUser = () => {
        if (data){
            if (data.userName){
                return data.userName
            }else if (data.sub){
                return data.sub
            }else {
                return ''
            }
        }
        return ''
    }

    return (
        <div>
            <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <Typography>{ getUser() }</Typography>
                <IconButton size="large" onClick={handleMenu} color="inherit"  >
                    <AccountCircle/>
                </IconButton>
            </Box>

            <Menu anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)} onClose={handleClose}
                  PaperProps={{
                      elevation: 0,
                      sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 0.5,
                          '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                          },
                          '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                          },
                      },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

                {/*<MenuItem onClick={handleClose}>*/}
                {/*    <FormControl sx={{ minWidth: 150, mt:1 }} size="small">*/}
                {/*        <InputLabel id="demo-select-small-label">{t('lang')}</InputLabel>*/}
                {/*        <Select*/}
                {/*            labelId="demo-select-small-label"*/}
                {/*            id="demo-select-small"*/}
                {/*            value={localization}*/}
                {/*            label={t('lang')}*/}
                {/*            onChange={handleChange}*/}
                {/*        >*/}
                {/*            <MenuItem value="ukUA"><em>Українська</em></MenuItem>*/}
                {/*            <MenuItem value={'enUS'}>English</MenuItem>*/}
                {/*            <MenuItem value={'plPL'}>Polski</MenuItem>*/}
                {/*            <MenuItem value={'ruRU'}>Радянська</MenuItem>*/}

                {/*        </Select>*/}
                {/*    </FormControl>*/}
                {/*</MenuItem>*/}
                {/*<MenuItem><ThemeSwitch/></MenuItem>*/}
                <Divider/>
                <MenuItem onClick={handleClose}>Profile</MenuItem>
            </Menu>
        </div>
    );
}

export default UserIcon;