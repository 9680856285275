export type TopUpCardStatus = 'CREATED' | 'IN_PROCESS' | 'FINISHED' | 'REJECTED' | 'ERROR'

export const topUpStatusNames: Map<TopUpCardStatus, string> = new Map([
    ['CREATED', 'Прийнято'],
    ['IN_PROCESS', 'В роботі'],
    ['FINISHED', 'Готово'],
    ['REJECTED', 'Відхилено'],
    ['ERROR', 'Помилка'],
]);


