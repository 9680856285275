import React from 'react'
import {Box} from "@mui/material";
import FinReportTable from "../components/reports/finReport/FinReportTable";


export default function FinReportPage({pageHeight}:{pageHeight: number}) {

    return (
        <Box sx={{ width: '100%', height:pageHeight + 'px', typography: 'body1'}}>
            <FinReportTable fullHeight={pageHeight} isAutoHeight={true}/>
        </Box>
    );
}
