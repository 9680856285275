import {config} from "../ApiConstants";
// import axios from "axios";
import {useAppDispatch} from "../hooks/appHooks";
import {AxiosError} from "axios/index";
import {setError} from "../reducers/message/globalMessageSlice";
import {IRequestQueryMultiple} from "../model/pageRequestQuery/IRequestQueryMultiple";
import {IFinReport, IFinReportPage} from "../model/finReport/IFinReport";
import axiosApi from "../axiosApi/axiosConfig";

export function useFinReportController(){
    const dispatch = useAppDispatch()

    async function getReportByDateRange(queryParams:IRequestQueryMultiple): Promise<IFinReport[]>{
        try{
            const response = await axiosApi.post<IFinReport[]>(config.url.FIN_REPORT + '/dataTimePeriod', queryParams)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання звіту.', message:(error.response?.data as any).message}))
            return []
        }
    }

    async function getReportByDays(queryParams:IRequestQueryMultiple): Promise<IFinReportPage>{
        try{
            const response = await axiosApi.post<IFinReport[]>(config.url.FIN_REPORT + '/byDays', queryParams)
            const totalRows:number = parseInt(response.headers['amount-rows'])
            return {rows: response.data, totalRows: totalRows}
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання звітів.', message:(error.response?.data as any).message}))
            return {rows: [], totalRows: 0}
        }

    }


    return {
        getReportByDateRange,
        getReportByDays

    }

}