import React, {useEffect, useState} from 'react';
import {IFilters} from "../../app/model/pageRequestQuery/IFilters";
import {ICity} from "../../app/model/city/ICity";
import {useCityController} from "../../app/controllers/useCityController";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";
import {useAppDispatch, useAppSelector} from "../../app/hooks/appHooks";
import {setCities, setCurrentCity} from "../../app/reducers/city/citySlice";

interface IProps{
    onCityChange(cityId:number): void
    filters:IFilters | undefined
}
function CitySelect({onCityChange, filters}:IProps) {
    const { cities, currentCity } = useAppSelector((state) => state.city.state)
    const {getCities} = useCityController()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if(cities.length === 0){
            getCities().then(value => {
                dispatch(setCities(value))
            });
        }
    }, []);

    const handleChange = (event: SelectChangeEvent<number>) => {
        const cityId = event.target.value as number
        const city = cities.find(city => city.id === cityId);
        if(city){
            dispatch(setCurrentCity(city))
        }else{
            dispatch(setCurrentCity({id:0, name:'Не вибрано'}))
        }
        onCityChange(cityId)
    };

    return (
        <FormControl size='small' sx={{maxWidth:200, ml:3}}>
            <InputLabel size='small' id="cities-select-label">Місто</InputLabel>
            <Select
                labelId="cities-select-label"
                value={currentCity.id}
                label="Місто"
                size='small'
                onChange={handleChange}
            >
                <MenuItem key={0} value={0}>
                    {"Не вибрано"}
                </MenuItem>
                {cities.map((city) => (
                    <MenuItem key={city.id} value={city.id}>
                        {city.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default CitySelect;