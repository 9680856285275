export type FraudState = 'UNPROCESSED' | 'IN_WORK' | 'WITH_FRAUD' | 'TRUE_ORDER'

export const fraudStateNames: Map<FraudState, string> = new Map([
    ['UNPROCESSED', 'Не опрацьовано'],
    ['IN_WORK', 'В роботі'],
    ['WITH_FRAUD', 'Шахрайство'],
    ['TRUE_ORDER', 'Веріфіковано'],
]);


