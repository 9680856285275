import {config} from "../ApiConstants";
import {useAppDispatch} from "../hooks/appHooks";
import {AxiosError} from "axios/index";
import {setError} from "../reducers/message/globalMessageSlice";
import {IRequestQueryMultiple} from "../model/pageRequestQuery/IRequestQueryMultiple";
import axiosApi from "../axiosApi/axiosConfig";
import {IFraudItem} from "../model/fraud/IFraudReport";

export function useFraudReportController(){
    const dispatch = useAppDispatch()

    async function getReportByDateRange(queryParams:IRequestQueryMultiple): Promise<IFraudItem[]>{
        try{
            const response = await axiosApi.post<IFraudItem[]>(config.url.FRAUD_REPORT + '/dataTimePeriod', queryParams)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання звіту.', message:(error.response?.data as any).message}))
            return []
        }
    }

    async function updateOrder(operation: IFraudItem):Promise<IFraudItem | undefined>{
        try {
            let response = await axiosApi.patch<IFraudItem>(config.url.FRAUD_REPORT + '/order', operation);
            return response.data
        } catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка відправки замовлення.', message:(error.response?.data as any).message}))
        }
    }


    return {
        getReportByDateRange,
        updateOrder
    }

}