import * as React from 'react';

import Box from '@mui/material/Box';
import {
    converterStringTimestampToDate,
    dateFormatTimeLast
} from "../../../app/utils/DataUtil";
import TableEmptyOverlay from "../../overlay/TableEmptyOverlay";
import StyledOperationTable from "../StyledOperationTable";
import {useCallback, useEffect, useState} from "react";
import {
    GridColDef,
    GridValueFormatterParams,
    GridValueGetterParams,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridColumnVisibilityModel,
    GridRenderCellParams, GridRowModel, GridRowClassNameParams, GridToolbarExport,
} from "@mui/x-data-grid-pro";
import {Link} from "@mui/material";
import {IFraudItem} from "../../../app/model/fraud/IFraudReport";
import {useFraudReportController} from "../../../app/controllers/useFraudReportController";
import {setError} from "../../../app/reducers/message/globalMessageSlice";
import {useAppDispatch} from "../../../app/hooks/appHooks";
import {AxiosError} from "axios";
import {fraudStateNames} from "../../../app/model/fraud/FraudState";
import {IFilters} from "../../../app/model/pageRequestQuery/IFilters";
import dayjs from "dayjs";
import {IRequestQueryMultiple} from "../../../app/model/pageRequestQuery/IRequestQueryMultiple";
import {IFilter} from "../../../app/model/pageRequestQuery/IFilter";
import DateTimeRange from "../../dateTimeRange/DateTimeRange";
import CitySelect from "../../cityes/CitySelect";

const dateTimeConverter = (params: GridValueGetterParams<any, any>):Date | undefined => {
    return converterStringTimestampToDate(params.row[params.field])
}

const renderOrderLink = (params: GridRenderCellParams<any, string>) => {
    const link = "https://admin.ontaxi.com.ua/orders/" + params.value + "?tab=1"
    return <Link href={link} underline="hover" target="_blank">
        {params.value}
    </Link>
}

const renderDriverLink = (params: GridRenderCellParams<any, string>) => {
    const driverId = params.row.driverId
    const link = "https://admin.ontaxi.com.ua/drivers/" + driverId + "?tab=3"
    return  <Box sx={{width: '100%', display:'flex' }}>
        <Link href={link} underline="always" target="_blank" sx={{alignContent:'left'}}>
            {params.value}
        </Link>
    </Box>

}


const currencyFormatter = new Intl.NumberFormat('ru');

const formatSum = (params: GridValueFormatterParams<number>) => {
    if (params.value === 0){
        return ''
    }
    return currencyFormatter.format(params.value)
}


const formatDate = (params: GridValueFormatterParams<Date>) => {
    return dateFormatTimeLast(params.value)
}

const getStatusOptions = (): { value: string, label: string }[] => {
    const options: { value: string, label: string }[] = []
    fraudStateNames.forEach((value, key) => {
        options.push({value: key, label: value})
    })
    return options
}

function NoRowsOverlay() {
    return (
        <TableEmptyOverlay text={'Нема операцій'}/>
    );
}

function styles(params: GridRowClassNameParams<any>):string{
    const operation = params.row as IFraudItem
    if (operation.fraudState === 'IN_WORK'){
        return 'row-theme--processed'
    } else if(operation.fraudState === 'TRUE_ORDER'){
        return 'row-theme--done'
    } else if(operation.fraudState === 'WITH_FRAUD'){
        return 'row-theme--error'
    }
    return ''
}

interface IProps {
    isAutoHeight:boolean
    fullHeight:number
}

export default function FraudReportTable({isAutoHeight, fullHeight}: IProps) {
    const [isPending, setPending] = useState(false)
    const [report, setReport] = useState<IFraudItem[]>([])
    const dispatch = useAppDispatch()
    const [columnVisibleModel, setColumnVisibleModel] = useState<GridColumnVisibilityModel>({id:true})
    const {updateOrder, getReportByDateRange} = useFraudReportController()
    const [dateRangeFilter, setRangeFilter] = useState<IFilters>({
        filters:[
            {field:'closed', operator:'after', value:dayjs(new Date()).set('hour',0).set('minute',0).format('YYYY-MM-DDTHH:mm')},
            {field:'closed', operator:'before', value:dayjs(new Date()).set('hour',23).set('minute',59).format('YYYY-MM-DDTHH:mm')},
        ],  operator:'and'
    })

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID замовлення', type: 'string', minWidth: 150, maxWidth:200, flex: 1, renderCell: renderOrderLink },
        { field: 'fraudState', headerName: 'Статус', type: 'singleSelect', minWidth: 120, maxWidth:200, flex: 1, editable:true, align:'center', headerAlign:'center',
            valueOptions: getStatusOptions },
        { field: 'cost', headerName: 'Ціна', type: 'number', minWidth: 100, maxWidth:200, flex: 0.7 },
        { field: 'driverName', headerName: 'Ім`я водія', type: 'string', minWidth: 130, maxWidth:200, flex: 1, renderCell: renderDriverLink },
        { field: 'driverPhone', headerName: "Телефон водія", type: 'string', minWidth: 130, maxWidth:200, flex: 1 },
        { field: 'percentageSuspiciousOrders', headerName: 'Підозрілі (%)', type: 'number', minWidth: 100, maxWidth:200, flex: 1 },
        { field: 'clientName', headerName: "Ім'я клієнта", type: 'string', minWidth: 110, maxWidth:200, flex: 1 },
        { field: 'clientPhone', headerName: "Телефон клієнта", type: 'string', minWidth: 130, maxWidth:200, flex: 1 },
        { field: 'percentageRejectedByClientOrders', headerName: "Скасованих (%)", type: 'number', minWidth: 120, maxWidth:200, flex: 1 },
        { field: 'percentageNoClientOrdersForClient', headerName: "Нема клієнта (%)", type: 'number', minWidth: 130, maxWidth:200, flex: 1 },
        { field: 'managerComment', headerName: "Коментар", type: 'string', minWidth: 120, maxWidth:200, flex: 1, editable:true },
        { field: 'created', headerName: 'Створено', type: 'dateTime', minWidth: 155, maxWidth:200, flex: 1, valueGetter: dateTimeConverter, valueFormatter: formatDate },

    ];

    useEffect(() => {
        fetchOperations({filter: dateRangeFilter})
    }, []);

    async function fetchOperations(query: IRequestQueryMultiple){
        setPending(true)
        await fetchByDateRange(query)
        setPending(false)
    }

    async function fetchByDateRange(query: IRequestQueryMultiple) {
        const report = await getReportByDateRange(query);
        setReport(report)
    }

    const rangeFilterHandler = (dateRangeFilter: IFilters) => {
        setRangeFilter(dateRangeFilter)
        fetchOperations({filter: dateRangeFilter})
    }

    function insertCityFilter(cityId: number, filter: IFilters): IFilters {
        if (filter && filter.filters.length > 0) {
            const newFilters = [...filter.filters]
            if (cityId !== 0) {
                const cityFilter = newFilters.filter(value => value.field === 'cityId');
                if (cityFilter.length === 0) {
                    const filter: IFilter = {field: 'cityId', operator: '=', value: cityId}
                    newFilters.push(filter)
                } else {
                    cityFilter[0].value = cityId
                }
                return {filters: newFilters, operator: 'and'}
            } else {
                let noCityFilter = newFilters.filter(value => value.field !== 'cityId');
                return {filters: noCityFilter, operator: 'and'}
            }
        } else {
            console.error("Incorrect input filter value for date range")
        }
        return {filters:[], operator: 'and'}
    }

    const cityFilterHandler = (cityId: number) => {
        const filter = insertCityFilter(cityId, dateRangeFilter);
        setRangeFilter(filter)

        fetchOperations({filter: filter})
    }

    const processRowUpdate = useCallback((newRow: GridRowModel, oldRow: GridRowModel) =>
            new Promise<GridRowModel>((resolve, reject) => {
                updateOrder( { ...newRow as IFraudItem }).then(value => {
                    if (value){
                        resolve(value);
                    }else{
                        resolve(oldRow)
                    }
                }).catch(reason => {
                    resolve(oldRow)
                    const error = reason.error as AxiosError
                    dispatch(setError({title:'Помилка оновлення замовлення.', message:(error.response?.data as any).message}))
                })
            }),
        [],
    );

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{mt:1, justifyContent:'space-between'}}>
                <Box sx={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                    <GridToolbarColumnsButton/>
                    <GridToolbarFilterButton/>
                    <GridToolbarExport />
                    <DateTimeRange fieldName={"closed"} filters={dateRangeFilter} onTimeRangeChange={rangeFilterHandler}/>
                    <CitySelect onCityChange={cityFilterHandler} filters={dateRangeFilter}/>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{width: '100%', height: fullHeight + 'px'}}>
            <StyledOperationTable
                rows={ report }
                columns={columns}
                loading={isPending}
                density="compact"
                autoHeight={isAutoHeight}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'asc' }],
                    },
                }}
                hideFooter={true}
                hideFooterPagination={true}
                hideFooterSelectedRowCount
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                    toolbar: CustomToolbar,
                }}
                columnVisibilityModel={columnVisibleModel}
                onColumnVisibilityModelChange={setColumnVisibleModel}
                processRowUpdate={processRowUpdate}
                getRowClassName={styles}
            />
        </Box>
    );
}