import {config} from "../ApiConstants";
import {useAppDispatch} from "../hooks/appHooks";
import {AxiosError} from "axios/index";
import {setError} from "../reducers/message/globalMessageSlice";
import {IRequestQueryMultiple} from "../model/pageRequestQuery/IRequestQueryMultiple";
import axiosApi from "../axiosApi/axiosConfig";
import {
    ITransferFoundsOperation,
    ITransferFoundsOperationPage
} from "../model/transferFoundsOperation/ITransferFoundsOperation";

export function useTransferFoundsOperationController(){
    const dispatch = useAppDispatch()

    async function getOperations(queryParams:IRequestQueryMultiple): Promise<ITransferFoundsOperationPage>{
        try{
            const response = await axiosApi.post<ITransferFoundsOperation[]>(config.url.TRANSFER_FUND_OPERATION + '/page', queryParams)
            const totalRows:number = parseInt(response.headers['amount-rows'])
            return {operations: response.data, totalRows: totalRows}
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання операцій переказу.', message:(error.response?.data as any).message}))
            return {operations: [], totalRows: 0}
        }
    }

    return {
        getOperations,

    }

}