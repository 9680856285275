import * as React from 'react';
// import { DataGrid } from '@mui/x-data-grid';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import { darken, lighten, styled } from '@mui/material/styles';

export enum styleConst{

}

const getBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledOperationTable = styled(DataGrid)(({ theme }) => ({
    '& .row-theme--accepted': {
        backgroundColor: getBackgroundColor(theme.palette.info.dark, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.info.dark, theme.palette.mode),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(theme.palette.info.dark, theme.palette.mode),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(theme.palette.info.dark, theme.palette.mode),
            },
        },
    },
    '& .row-theme--processed': {
        backgroundColor: getBackgroundColor(theme.palette.info.light, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.info.light, theme.palette.mode),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(theme.palette.info.light, theme.palette.mode),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(theme.palette.info.light, theme.palette.mode),
            },
        },
    },
    '& .row-theme--done': {
        backgroundColor: getBackgroundColor(theme.palette.success.main, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(theme.palette.success.main, theme.palette.mode),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(theme.palette.success.main, theme.palette.mode),
            },
        },
    },
    '& .row-theme--error': {
        backgroundColor: getBackgroundColor(theme.palette.error.main, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(theme.palette.error.main, theme.palette.mode),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
            },
        },
    },
    '& .row-theme--at-work': {
        backgroundColor: getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.warning.main, theme.palette.mode),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(theme.palette.warning.main, theme.palette.mode),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(theme.palette.warning.main, theme.palette.mode),
            },
        },
    },
}));

export default StyledOperationTable;