import * as React from 'react';

import Box from '@mui/material/Box';
import {
    converterStringTimestampToDate,
    dateFormatTimeLast
} from "../../app/utils/DataUtil";
import TableEmptyOverlay from "../overlay/TableEmptyOverlay";
import {useCallback, useEffect, useState} from "react";
import {
    GridColDef,
    GridFilterModel,
    GridRowClassNameParams,
    GridValueFormatterParams,
    GridValueGetterParams,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridTreeNodeWithRender,
    GridToolbarColumnsButton,
    GridRowModel,
    GridRenderCellParams,
    GridColumnVisibilityModel,
    useGridApiRef,
    gridFilteredSortedRowEntriesSelector,
    GridToolbarExport, GridToolbar, GridEventListener,
} from "@mui/x-data-grid-pro";
import {Button, ButtonGroup, IconButton, Link, useMediaQuery, useTheme} from "@mui/material";
import {IRequestQueryMultiple} from "../../app/model/pageRequestQuery/IRequestQueryMultiple";
import {IFilters} from "../../app/model/pageRequestQuery/IFilters";
import DateTimeRange from "../dateTimeRange/DateTimeRange";
import dayjs from "dayjs";
import {useAppDispatch} from "../../app/hooks/appHooks";
import {getBankName} from "../../app/model/binChecker/binChecker";
import {topUpStatusNames} from "../../app/model/topUpCardOperation/TopUpCardStatus";
import StyledStatusTable from "./StyledStatusTable";
import {ITopUpCardOperation} from "../../app/model/topUpCardOperation/ITopUpCardOperation";
import {useTopUpCardOperationController} from "../../app/controllers/useTopUpCardOperationController";
import {AxiosError} from "axios";
import {setError} from "../../app/reducers/message/globalMessageSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SyncIcon from "@mui/icons-material/Sync";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Drawer from "@mui/material/Drawer";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {GridRowModesModel} from "@mui/x-data-grid/models/api/gridEditingApi";
import {GridCallbackDetails} from "@mui/x-data-grid/models/api";

const dateTimeConverter = (params: GridValueGetterParams<any, any>):Date | undefined => {
    return converterStringTimestampToDate(params.row[params.field])
}

const currencyFormatter = new Intl.NumberFormat('ru');

const formatSum = (params: GridValueFormatterParams<number>) => {
    if (params.value === 0){
        return ''
    }
    const sum = params.value / 100.0
    return currencyFormatter.format(sum)
}

const renderLink = (params: GridRenderCellParams<any, string>) => {
    const driverId = params.row['driverId']
    const link = "https://admin.ontaxi.com.ua/drivers/" + driverId + "?tab=3"
    return <Box>
        {
            params.id !== 0 &&
            <Link href={link} underline="hover" target="_blank" >
                {params.value}
            </Link>
        }
    </Box>
}

const formatDate = (params: GridValueFormatterParams<Date>) => {
    return dateFormatTimeLast(params.value)
}

function NoRowsOverlay() {
    return (
        <TableEmptyOverlay text={'Нема операцій'}/>
    );
}

const formatCardNumber = (params: GridValueFormatterParams<string>) => {
    if (params.value === '' || params.value === undefined){
        return ''
    }
    // Remove any existing dashes or spaces from the card number
    const cleanedNumber = params.value.replace(/[-\s]/g, '');
    // Split the cleaned number into groups of 4 digits
    const groups = cleanedNumber.match(/.{1,4}/g);
    // Join the groups with dashes to create the formatted number
    return  groups?.join(' ');
}

const bankGetter = (params: GridValueGetterParams<any, any, GridTreeNodeWithRender>) => {
    const cardNumber = params.row.cardNumber
    return getBankName(cardNumber)
}

const getStatusOptions = (): { value: string, label: string }[] => {
    const options: { value: string, label: string }[] = []
    topUpStatusNames.forEach((value, key) => {
        options.push({value: key, label: value})
    })
    return options
}

function styles(params: GridRowClassNameParams<any>):string{
    const operation = params.row as ITopUpCardOperation
    if (operation.status === 'CREATED') {
        return 'row-theme--accepted'
    }else if (operation.status === 'IN_PROCESS'){
        return 'row-theme--processed'
    }else if (operation.status === 'REJECTED'){
        return 'row-theme--at-work'
    } else if(operation.status === 'FINISHED'){
        return 'row-theme--done'
    } else if(operation.status === 'ERROR'){
        return 'row-theme--error'
    }
    return ''
}

interface IProps {
    isAutoHeight:boolean
    fullHeight:number
}

export default function CardsDepositTable({ isAutoHeight, fullHeight }: IProps) {
    const [isPending, setPending] = useState(false)
    const [operations, setOperations] = useState<ITopUpCardOperation[]>([])
    const [filteredOperations, setFilteredOperations] = useState<ITopUpCardOperation[]>([])
    const [dateRangeFilter, setDateRangeFilter] = useState<IFilters>({
        filters:[
            {field:'createDateTime', operator:'after', value:dayjs(new Date()).subtract(3, "day").set('hour',0).set('minute',0).format('YYYY-MM-DDTHH:mm')},
            {field:'createDateTime', operator:'before', value:dayjs(new Date()).set('hour',23).set('minute',59).format('YYYY-MM-DDTHH:mm')}
        ], operator:'and'
    })
    const [columnVisibleModel, setColumnVisibleModel] = useState<GridColumnVisibilityModel>({id:false,})
    const [lastQuery, setLastQuery] = useState<IRequestQueryMultiple | undefined>(undefined)
    const [isCreatedHide, setIsCreatedHide] = useState(false)
    const [isProcessedHide, setIsProcessedHide] = useState(false)
    const [isFinishedHide, setFinishedHide] = useState(true)
    const [isRejectedHide, setRejectedHide] = useState(true)
    const [isErrorHide, setErrorHide] = useState(true)
    const {updateOperation, getOperations } = useTopUpCardOperationController()
    const [pinnedSumRow, setPinnedSumRow] = useState<any>({id:0})
    const apiRef = useGridApiRef();
    const dispatch = useAppDispatch()
    const theme = useTheme();
    const matches = useMediaQuery('(min-width:1350px)');
    const [openDraw, setOpenDraw] = React.useState(false);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'id', flex: 1, minWidth: 60, maxWidth:150, type: 'number', },
        { field: 'driverName', headerName: 'Ім`я', flex: 1, minWidth: 180, maxWidth:200, type: 'string', renderCell: renderLink },
        { field: 'driverPhone', headerName: 'Телефон', flex: 1, minWidth: 130, maxWidth:200, type: 'string', },
        { field: 'sum', headerName: 'Сума', flex: 1, minWidth: 110, maxWidth: 160, type: 'number', valueFormatter: formatSum },
        { field: 'amount', headerName: 'Поповнення', flex: 1, minWidth: 110, maxWidth: 160, type: 'number', valueFormatter: formatSum },
        { field: 'commission', headerName: 'Коміссія', flex: 1, minWidth: 110, maxWidth: 150, type: 'number', valueFormatter: formatSum},
        { field: 'cardNumber', headerName: 'Номер картки', flex: 1, minWidth: 160, maxWidth: 200, type: 'string', valueFormatter: formatCardNumber },
        { field: 'binChecker', headerName: 'Банк', flex: 1, minWidth: 100, maxWidth: 200, type: 'string', valueGetter:bankGetter },
        { field: 'status', headerName: 'Статус', flex: 1, minWidth: 120, maxWidth:250, type: 'singleSelect', editable:true, align:'center', headerAlign:'center',
            valueOptions: getStatusOptions  },
        { field: 'createDateTime', headerName: 'Дата створення', flex: 1, minWidth: 155, maxWidth: 160, type: 'dateTime', valueGetter: dateTimeConverter, valueFormatter: formatDate },
        { field: 'updateDateTime', headerName: 'Дата оновлення', flex: 1, minWidth: 155, maxWidth: 160, type: 'dateTime', valueGetter: dateTimeConverter, valueFormatter: formatDate },
        { field: 'comment', headerName: 'Коментар', flex: 1.5, minWidth: 100, maxWidth:250, type: 'string', editable:true},
    ];

    useEffect(() => {
        fetchOperations({filter: dateRangeFilter})
    }, []);

    useEffect(() => {
        const filteredOperations = getFilteredOperations(operations)
        setFilteredOperations(filteredOperations)
    }, [operations, isCreatedHide, isProcessedHide, isFinishedHide, isRejectedHide, isErrorHide]);

    useEffect(() => {
        calculateCommonRow()
    }, [filteredOperations]);

    async function fetchOperations(query: IRequestQueryMultiple){
        setPending(true)
        const operationsPage = await getOperations(query);
        setOperations(operationsPage.operations)
        setPending(false)
        setLastQuery(query)
    }


    const getFilteredOperations = (operations: ITopUpCardOperation[]): ITopUpCardOperation[] => {
        let filteredOperations:ITopUpCardOperation[] = operations
        if (isCreatedHide){
            filteredOperations = filteredOperations.filter(value => value.status !== 'CREATED')
        }
        if (isProcessedHide){
            filteredOperations = filteredOperations.filter(value => value.status !== 'IN_PROCESS')
        }
        if (isFinishedHide){
            filteredOperations = filteredOperations.filter(value => value.status !== 'FINISHED')
        }
        if (isRejectedHide){
            filteredOperations = filteredOperations.filter(value => value.status !== 'REJECTED')
        }
        if (isErrorHide){
            filteredOperations = filteredOperations.filter(value => value.status !== 'ERROR')
        }
        return filteredOperations
    }

    const updateOperations = () => {
        if (lastQuery !== undefined){
            fetchOperations(lastQuery)
        }
    }

    const onFilterChange = (model: GridFilterModel) => {
        calculateCommonRow()
    };

    const dateRangeFilterHandler = (dateRangeFilter: IFilters) => {
        setDateRangeFilter(dateRangeFilter)
        fetchOperations({filter:dateRangeFilter})
    }

    const processRowUpdate = useCallback((newRow: GridRowModel, oldRow: GridRowModel) =>
            new Promise<GridRowModel>((resolve, reject) => {
                updateOperation( { ...newRow as ITopUpCardOperation }).then(value => {
                    if (value){
                        resolve(value);
                    }else{
                        resolve(oldRow)
                    }
                }).catch(reason => {
                    resolve(oldRow)
                    const error = reason.error as AxiosError
                    dispatch(setError({title:'Помилка оновлення запиту на поповнення.', message:(error.response?.data as any).message}))
                })
            }),
        [],
    );

    const calculateCommonRow = () => {
        const filteredRows = gridFilteredSortedRowEntriesSelector(apiRef);
        let sum = 0
        let amount = 0
        let commission = 0
        filteredRows.forEach(value => {
            const tmpSum = value.model['sum']
            sum += tmpSum
            const tmpAmount = value.model['amount']
            amount += tmpAmount
            const tmpCommission = value.model['commission']
            commission += tmpCommission
        })
        const pinnedRowSummeData = Object.assign({id:0}, {sum: sum, amount: amount, commission:commission} );
        setPinnedSumRow(pinnedRowSummeData)
    }

    const buttons = [
        <Button sx={{mt: 1}} variant={isCreatedHide === true ? 'contained': 'outlined'}
                startIcon={isCreatedHide === true ? <CheckCircleIcon/>: <CancelIcon/>}
                onClick={event => setIsCreatedHide(prevState => !prevState)}>
            створено
        </Button>,
        <Button sx={{mt: 1}} variant={isProcessedHide === true ? 'contained': 'outlined'}
                startIcon={isProcessedHide === true ? <CheckCircleIcon/>: <CancelIcon/>}
                onClick={event => setIsProcessedHide(prevState => !prevState)}>
            в роботі
        </Button>,
        <Button sx={{mt: 1}} variant={isFinishedHide === true ? 'contained': 'outlined'}
                startIcon={isFinishedHide === true ? <CheckCircleIcon/>: <CancelIcon/>}
                onClick={event => setFinishedHide(prevState => !prevState)}>
            готово
        </Button>,
        <Button sx={{mt: 1}} variant={isRejectedHide === true ? 'contained': 'outlined'}
                startIcon={isRejectedHide === true ? <CheckCircleIcon/>: <CancelIcon/>}
                onClick={event => setRejectedHide(prevState => !prevState)}>
            відхилено
        </Button>,
        <Button sx={{mt: 1}} variant={isErrorHide === true ? 'contained': 'outlined'}
                startIcon={isErrorHide === true ? <CheckCircleIcon/>: <CancelIcon/>}
                onClick={event => setErrorHide(prevState => !prevState)}>
            помилка
        </Button>
    ];

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDraw(newOpen);
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{mt:1, justifyContent:'space-between'}}>
                <Box sx={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                    <IconButton aria-label="update" sx={{ml:1}} onClick={updateOperations}>
                        <SyncIcon />
                    </IconButton>
                    <GridToolbarColumnsButton/>
                    <GridToolbarFilterButton />
                    <GridToolbarExport />
                    <DateTimeRange filters={dateRangeFilter} fieldName={"createDateTime"} onTimeRangeChange={dateRangeFilterHandler}/>
                </Box>
                {matches ?
                    <ButtonGroup size="small" >
                        {buttons}
                    </ButtonGroup> :
                    <Box>
                        <Button onClick={toggleDrawer(true)} startIcon={<ArrowBackIosIcon/>} >
                            Фільтри
                        </Button>
                        <Drawer open={openDraw} onClose={toggleDrawer(false)} anchor={'right'}>
                            <Button onClick={toggleDrawer(false)} startIcon={<ArrowForwardIosIcon/>} sx={{width:20}}></Button>
                            <Box sx={{pl: 4, pt:2, pr:2, display:'flex', flexDirection:'column', }}>
                                {buttons}
                            </Box>
                        </Drawer>
                    </Box>
                }

            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{width: '100%', height: fullHeight + 'px'}}>
            <StyledStatusTable
                apiRef={apiRef}
                rows={filteredOperations}
                columns={columns}
                loading={isPending}
                density="compact"
                autoHeight={isAutoHeight}
                pinnedRows={{bottom:[pinnedSumRow]}}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'asc' }],
                    },
                }}
                hideFooter={true}
                hideFooterPagination={true}
                hideFooterSelectedRowCount
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                    toolbar: CustomToolbar,
                }}
                getRowClassName={styles}
                onRowModesModelChange = {(rowModesModel: GridRowModesModel, details: GridCallbackDetails) => calculateCommonRow()}
                // onFilterModelChange={(newFilterModel) => onFilterChange(newFilterModel)}
                columnVisibilityModel={columnVisibleModel}
                onColumnVisibilityModelChange={setColumnVisibleModel}
                processRowUpdate={processRowUpdate}
            />
        </Box>
    );
}