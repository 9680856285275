import * as React from 'react';
import { useEffect} from 'react';

import Box from '@mui/material/Box';
import CitySelect from "../cityes/CitySelect";
import Divider from "@mui/material/Divider";
import {
    Button, Checkbox, FormControl, FormHelperText, Input, InputAdornment, MenuItem, OutlinedInput,
    Paper, Select, Switch, Table, TableBody, TableCell, tableCellClasses,
    TableContainer, TableHead, TableRow, Typography
} from "@mui/material";
import {useDriverControlController} from "../../app/controllers/useDriverControlController";
import {useAppDispatch, useAppSelector} from "../../app/hooks/appHooks";
import {IDriverControlSettingsDto} from "../../app/model/drivers/IDriverControlSettings";
import {styled} from "@mui/material/styles";
import {setError, setOkMessage} from "../../app/reducers/message/globalMessageSlice";
import {
    Controller,
    DeepRequired,
    FieldErrorsImpl, GlobalError,
    SubmitHandler,
    useForm,
    UseFormRegisterReturn
} from "react-hook-form";
import {AxiosError} from "axios";
import ListItemText from "@mui/material/ListItemText";
import {SelectChangeEvent} from "@mui/material/Select";

function styledCell() {
    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
    }));
    return StyledTableCell;
}

function NumberField(props: {
    register: UseFormRegisterReturn<string>,
    endAdornment: string,
    helperText: string
}) {
    const StyledTableCell = styledCell();

    return <StyledTableCell align="right">
        <FormControl variant="standard" sx={{m: 1, width: "12ch"}}>
            <Input
                type="number" size={"small"} defaultValue={""}
                inputProps={{step: "0.1", "aria-label": "number-field"}}
                {...props.register}
                endAdornment={<InputAdornment position="end">{props.endAdornment}</InputAdornment>}
            />
            <FormHelperText>
                {props.helperText}
            </FormHelperText>
        </FormControl>
    </StyledTableCell>;
}

function DoubleNumberField(props: {
    registerFirst: UseFormRegisterReturn<string>,
    firstEndAdornment: string
    firstHelperText: string
    registerSecond: UseFormRegisterReturn<string>
    secondEndAdornment: string
    secondHelperText: string
}) {
    const StyledTableCell = styledCell();
    return <StyledTableCell align="right">
        <Box sx={{display: "flex", justifyContent:'space-between'}}>
            <FormControl variant="standard" sx={{m: 1, width: "10ch"}}>
                <Input
                    type="number" size={"small"} defaultValue={""}
                    inputProps={{step: "0.1", "aria-label": "first-number-field"}}
                    {...props.registerFirst}
                    endAdornment={<InputAdornment position="end">{props.firstEndAdornment}</InputAdornment>}
                />
                <FormHelperText>
                    {props.firstHelperText}
                </FormHelperText>
            </FormControl>
            <FormControl variant="standard" sx={{m: 1, width: "8ch"}}>
                <Input
                    type="number" size={"small"} defaultValue={""}
                    inputProps={{step: "0.1", "aria-label": "second-number-field"}}
                    {...props.registerSecond}
                    endAdornment={<InputAdornment position="end">{props.secondEndAdornment}</InputAdornment>}
                />
                <FormHelperText>
                    {props.secondHelperText}
                </FormHelperText>
            </FormControl>
        </Box>

    </StyledTableCell>;
}

const filtersNames = [
    'Тип оплати',
    'Якийсь Фільтр',
    'Ще якийсь фільтр',
];

const prioriryNames = [
    'VIP',
    'Рівень 1',
    'Рівень 2',
    'Рівень 3',
];

const verchileClassesNames = [
    'Эконом',
    'Стандарт',
    'Комфорт',
    'Бизнес',
    'Волонтер',
    'Грузовой - Мини',
    'Грузовой - Стандарт',
    'Грузовой - Макси',
    'Грузовой - Экстра',
    'Лоукост',
    'Мото',
    'Вело',
    'Эвакуатор',
    'BOAT',

];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function DriverBonusesSettings() {
    const {currentCity } = useAppSelector((state) => state.city.state)
    const [loading, setLoading] = React.useState(false)
    // const {getCriteriaSettingsByCityId, updateCriteriaSettings} = useDriverControlController()
    const dispatch = useAppDispatch()
    const [filterName, setFilterName] = React.useState<string[]>([]);
    const [priorityName, setPriorityName] = React.useState<string[]>([]);
    const [className, setClassName] = React.useState<string[]>([]);
    const {
        register,
        handleSubmit,
        reset,
        control ,
        formState: { errors, defaultValues },
    } = useForm<IDriverControlSettingsDto>({defaultValues:{
            LOCK_ADVANCED_FILTERS: {isAvailable:false, criterion:"LOCK_ADVANCED_FILTERS", cityId: currentCity.id},
            ACCEPTANCE_PERCENT: {isAvailable:false, criterion:"ACCEPTANCE_PERCENT", cityId: currentCity.id},
            AUTO_CAPTURE_OFF: {isAvailable:false, criterion:"AUTO_CAPTURE_OFF", cityId: currentCity.id},
            AUTO_CAPTURE_RADIUS: {isAvailable:false, criterion:"AUTO_CAPTURE_RADIUS", cityId: currentCity.id},
            PAYMENT_TYPE_ON: {isAvailable:false, criterion:"PAYMENT_TYPE_ON", cityId: currentCity.id},
            TELEGRAM_BOT_SUBSCRIPTION: {isAvailable:false, criterion:"PAYMENT_TYPE_ON", cityId: currentCity.id},
        }})



    const StyledTableCell = styledCell();

    useEffect(() => {
        getSettings(currentCity.id)
    },[])

    async function getSettings(cityId: number){
        // setLoading(true)
        // const settings = await getCriteriaSettingsByCityId(cityId)
        // if(settings){
        //     reset(settings)
        //     setLoading(false)
        // }
    }

    const onSubmit: SubmitHandler<IDriverControlSettingsDto> = (data) => {
        // setLoading(true)
        // for (let dataKey in data) {
        //     const restrictValue = data[dataKey as keyof IDriverControlSettingsDto];
        //     restrictValue.cityId = currentCity.id
        // }
        // updateCriteriaSettings(data).then(value => {
        //     reset(data)
        //     dispatch(setOkMessage({title:'Оновлення налаштувань збережені.', message:'Налаштування для міста ' + currentCity.name}))
        //     setLoading(false)
        // }).catch(reason => {
        //     const error = reason as AxiosError
        //     dispatch(setError({title:'Помилка збереження налаштувань.', message:'Налаштування для міста ' + currentCity.name + '. ' + (error.response?.data as any).message}))
        //     setLoading(false)
        // })
    }

    const cityFilterHandler = (cityId: number) => {
        getSettings(cityId)
    }

    const handleFilterNameChange = (event: SelectChangeEvent<typeof filterName>) => {
        const {
            target: { value },
        } = event;
        setFilterName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handlePriorityNameChange = (event: SelectChangeEvent<typeof priorityName>) => {
        const {
            target: { value },
        } = event;
        setPriorityName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleClassNameChange = (event: SelectChangeEvent<typeof verchileClassesNames>) => {
        const {
            target: { value },
        } = event;
        setClassName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    function getTwoEmptyCells() {
        return <>
            <StyledTableCell ></StyledTableCell>
            <StyledTableCell></StyledTableCell>
        </>;
    }

    function getTwoEmptyPrimaryCells() {
        return <>
            <StyledTableCell sx={{backgroundColor: "primary.dark", color: "white"}}></StyledTableCell>
            <StyledTableCell sx={{backgroundColor: "primary.dark", color: "white"}}></StyledTableCell>
        </>;
    }

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'grey.A100'
        }}>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <CitySelect onCityChange={cityFilterHandler} filters={undefined}/>
            </Box>
            <Divider variant="middle" sx={{mt: 2, mb: 2, ml: 8, mr: 8}}/>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Box sx={{display: 'flex', flexDirection: 'column', pl: '1%', pr: '1%', width: '100%', maxWidth: 1400}}>
                    <Typography sx={{ml: 4}}>
                        Накладання бонусів на водіїв
                    </Typography>
                    <TableContainer sx={{maxWidth: 1400}} component={Paper}>
                        <Table sx={{minWidth: 600, maxWidth: 1400}} size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" colSpan={1}></StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>Перший рівень бонусів</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>Другий рівень бонусів</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>Третій рівень бонусів</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell>Назва критеріїв</StyledTableCell>
                                    <StyledTableCell align="right">Значення</StyledTableCell>
                                    <StyledTableCell align="right">Вкл/Викл</StyledTableCell>
                                    <StyledTableCell align="right">Значення</StyledTableCell>
                                    <StyledTableCell align="right">Вкл/Викл</StyledTableCell>
                                    <StyledTableCell align="right">Значення</StyledTableCell>
                                    <StyledTableCell align="right">Вкл/Викл</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/*Відсоток прийняття замовлень*/}
                                <TableRow key={0} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">
                                        Відсоток прийняття замовлень
                                    </StyledTableCell>

                                    {/*LEVEL 1*/}
                                    <NumberField
                                        register={register("LOCK_ADVANCED_FILTERS.restrictions.LOCK_DRIVER.value", {
                                            required: 'Потрібно ввести відсоток',
                                            min: 0
                                        })} endAdornment={"%"} helperText={"мин. відсоток"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 2*/}
                                    <NumberField
                                        register={register("LOCK_ADVANCED_FILTERS.restrictions.LOCK_DRIVER.value", {
                                            required: 'Потрібно ввести відсоток',
                                            min: 0
                                        })} endAdornment={"%"} helperText={"мин. відсоток"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 3*/}
                                    <NumberField
                                        register={register("LOCK_ADVANCED_FILTERS.restrictions.LOCK_DRIVER.value", {
                                            required: 'Потрібно ввести відсоток',
                                            min: 0
                                        })} endAdornment={"%"} helperText={"мин. відсоток"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>
                                {/*Радіус автозахоплення*/}
                                <TableRow key={1} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">
                                        Радіус автозахоплення
                                    </StyledTableCell>

                                    {/*LEVEL 1*/}
                                    <NumberField
                                        register={register("LOCK_ADVANCED_FILTERS.restrictions.LOCK_DRIVER.value", {
                                            required: 'Потрібно ввести радіус',
                                            min: 0
                                        })} endAdornment={"м."} helperText={"мин. радіус"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 2*/}
                                    <NumberField
                                        register={register("LOCK_ADVANCED_FILTERS.restrictions.LOCK_DRIVER.value", {
                                            required: 'Потрібно ввести радіус',
                                            min: 0
                                        })} endAdornment={"м."} helperText={"мин. радіус"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 3*/}
                                    <NumberField
                                        register={register("LOCK_ADVANCED_FILTERS.restrictions.LOCK_DRIVER.value", {
                                            required: 'Потрібно ввести радіус',
                                            min: 0
                                        })} endAdornment={"м."} helperText={"мин. радіус"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>
                                {/*Просунутий фільтр*/}
                                <TableRow key={2} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">
                                        Просунутий фільтр
                                    </StyledTableCell>

                                    {/*LEVEL 1*/}
                                    <StyledTableCell align="right">
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 2*/}
                                    <StyledTableCell align="right">
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 3*/}
                                    <StyledTableCell align="right">
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>
                                {/*Відсутність налаштування деяких типів фільтрів у просунутому фільтрі*/}
                                <TableRow key={3} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">
                                        Відсутність типів фільтрів у просунутому фільтрі
                                    </StyledTableCell>

                                    {/*LEVEL 1*/}
                                    <StyledTableCell align="right">
                                        <Select sx={{minWidth: 160, maxWidth:200}} size={"small"} input={<OutlinedInput label="Tag" />} MenuProps={MenuProps}
                                            multiple
                                            value={filterName}
                                            onChange={handleFilterNameChange}
                                            renderValue={(selected) => selected.join(', ')}
                                        >
                                            {filtersNames.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    <Checkbox checked={filterName.includes(name)} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 2*/}
                                    <StyledTableCell align="right">
                                        <Select sx={{minWidth: 160, maxWidth:200}} size={"small"} input={<OutlinedInput label="Tag" />} MenuProps={MenuProps}
                                                multiple
                                                value={filterName}
                                                onChange={handleFilterNameChange}
                                                renderValue={(selected) => selected.join(', ')}
                                        >
                                            {filtersNames.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    <Checkbox checked={filterName.includes(name)} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 3*/}
                                    <StyledTableCell align="right">
                                        <Select sx={{minWidth: 160, maxWidth:200}} size={"small"} input={<OutlinedInput label="Tag" />} MenuProps={MenuProps}
                                                multiple
                                                value={filterName}
                                                onChange={handleFilterNameChange}
                                                renderValue={(selected) => selected.join(', ')}
                                        >
                                            {filtersNames.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    <Checkbox checked={filterName.includes(name)} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>
                                {/*Мінімальний рейтинг*/}
                                <TableRow key={4} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">
                                        Мінімальний рейтинг
                                    </StyledTableCell>

                                    {/*LEVEL 1*/}
                                    <NumberField
                                        register={register("LOCK_ADVANCED_FILTERS.restrictions.LOCK_DRIVER.value", {
                                            required: 'Потрібно ввести рейтинг',
                                            min: 0
                                        })} endAdornment={""} helperText={"мин. рейтинг"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 2*/}
                                    <NumberField
                                        register={register("LOCK_ADVANCED_FILTERS.restrictions.LOCK_DRIVER.value", {
                                            required: 'Потрібно ввести рейтинг',
                                            min: 0
                                        })} endAdornment={""} helperText={"мин. рейтинг"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 3*/}
                                    <NumberField
                                        register={register("LOCK_ADVANCED_FILTERS.restrictions.LOCK_DRIVER.value", {
                                            required: 'Потрібно ввести рейтинг',
                                            min: 0
                                        })} endAdornment={""} helperText={"мин. рейтинг"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>
                                {/*Виконані замовлення за минулий період*/}
                                <TableRow key={5} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">
                                        Виконані замовлення за минулий період
                                    </StyledTableCell>

                                    {/*LEVEL 1*/}
                                    <DoubleNumberField
                                        registerFirst={register("AUTO_CAPTURE_RADIUS.restrictions.SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE.additionalValue", {
                                            required: 'Потрібно ввести кількість замовлень',
                                            min: 0
                                        })}
                                        firstEndAdornment={"од."}
                                        firstHelperText={"замовлень"}
                                        registerSecond={register("AUTO_CAPTURE_RADIUS.restrictions.SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE.value", {
                                            required: 'Потрібно ввести кількість днів',
                                            min: 0
                                        })}
                                        secondEndAdornment={"од."}
                                        secondHelperText={"днів"}
                                    />
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 2*/}
                                    <DoubleNumberField
                                        registerFirst={register("AUTO_CAPTURE_RADIUS.restrictions.SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE.additionalValue", {
                                            required: 'Потрібно ввести кількість замовлень',
                                            min: 0
                                        })}
                                        firstEndAdornment={"од."}
                                        firstHelperText={"замовлень"}
                                        registerSecond={register("AUTO_CAPTURE_RADIUS.restrictions.SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE.value", {
                                            required: 'Потрібно ввести кількість днів',
                                            min: 0
                                        })}
                                        secondEndAdornment={"од."}
                                        secondHelperText={"днів"}
                                    />
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 3*/}
                                    <DoubleNumberField
                                        registerFirst={register("AUTO_CAPTURE_RADIUS.restrictions.SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE.additionalValue", {
                                            required: 'Потрібно ввести кількість замовлень',
                                            min: 0
                                        })}
                                        firstEndAdornment={"од."}
                                        firstHelperText={"замовлень"}
                                        registerSecond={register("AUTO_CAPTURE_RADIUS.restrictions.SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE.value", {
                                            required: 'Потрібно ввести кількість днів',
                                            min: 0
                                        })}
                                        secondEndAdornment={"од."}
                                        secondHelperText={"днів"}
                                    />
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>
                                {/*Класи автомобілів в фільтрі*/}
                                <TableRow key={6} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">
                                        Класи автомобілів в фільтрі
                                    </StyledTableCell>

                                    {/*LEVEL 1*/}
                                    <StyledTableCell align="right">
                                        <Select sx={{minWidth: 160, maxWidth:200}} size={"small"} input={<OutlinedInput label="Tag" />} MenuProps={MenuProps}
                                                value={className}
                                                multiple
                                                onChange={handleClassNameChange}
                                                renderValue={(selected) => selected.join(', ')}
                                        >
                                            {verchileClassesNames.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    <Checkbox checked={className.includes(name)} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 2*/}
                                    <StyledTableCell align="right">
                                        <Select sx={{minWidth: 160, maxWidth:200}} size={"small"} input={<OutlinedInput label="Tag" />} MenuProps={MenuProps}
                                                value={className}
                                                multiple
                                                onChange={handleClassNameChange}
                                                renderValue={(selected) => selected.join(', ')}
                                        >
                                            {verchileClassesNames.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    <Checkbox checked={className.includes(name)} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 3*/}
                                    <StyledTableCell align="right">
                                        <Select sx={{minWidth: 160, maxWidth:200}} size={"small"} input={<OutlinedInput label="Tag" />} MenuProps={MenuProps}
                                                value={className}
                                                multiple
                                                onChange={handleClassNameChange}
                                                renderValue={(selected) => selected.join(', ')}
                                        >
                                            {verchileClassesNames.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    <Checkbox checked={className.includes(name)} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>

                                {/*-----------------BONUSES-------------------*/}

                                <TableRow key={7} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row" sx={{backgroundColor: "primary.dark", color: "white"}}>
                                        Назва бонусів
                                    </StyledTableCell>

                                    {/*LEVEL 1*/}
                                    {getTwoEmptyPrimaryCells()}
                                    {/*LEVEL 2*/}
                                    {getTwoEmptyPrimaryCells()}
                                    {/*LEVEL 3*/}
                                    {getTwoEmptyPrimaryCells()}
                                </TableRow>
                                {/*Пріорітет*/}
                                <TableRow key={8} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">
                                        Пріорітет
                                    </StyledTableCell>

                                    {/*LEVEL 1*/}
                                    <StyledTableCell align="right">
                                        <Select sx={{minWidth: 160, maxWidth:200}} size={"small"} input={<OutlinedInput label="Tag" />} MenuProps={MenuProps}
                                                value={priorityName}
                                                onChange={handlePriorityNameChange}
                                                renderValue={(selected) => selected.join(', ')}
                                        >
                                            {prioriryNames.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    <Checkbox checked={priorityName.includes(name)} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 2*/}
                                    <StyledTableCell align="right">
                                        <Select sx={{minWidth: 160, maxWidth:200}} size={"small"} input={<OutlinedInput label="Tag" />} MenuProps={MenuProps}
                                                value={priorityName}
                                                onChange={handlePriorityNameChange}
                                                renderValue={(selected) => selected.join(', ')}
                                        >
                                            {prioriryNames.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    <Checkbox checked={priorityName.includes(name)} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 3*/}
                                    <StyledTableCell align="right">
                                        <Select sx={{minWidth: 160, maxWidth:200}} size={"small"} input={<OutlinedInput label="Tag" />} MenuProps={MenuProps}
                                                value={priorityName}
                                                onChange={handlePriorityNameChange}
                                                renderValue={(selected) => selected.join(', ')}
                                        >
                                            {prioriryNames.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    <Checkbox checked={priorityName.includes(name)} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>
                                {/*Зменьшення проценту коміссії*/}
                                <TableRow key={9} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">
                                        Зменьшення проценту коміссії
                                    </StyledTableCell>

                                    {/*LEVEL 1*/}
                                    <NumberField
                                        register={register("LOCK_ADVANCED_FILTERS.restrictions.LOCK_DRIVER.value", {
                                            required: 'Потрібно ввести процент',
                                            min: 0
                                        })} endAdornment={"%"} helperText={"коміссія"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 2*/}
                                    <NumberField
                                        register={register("LOCK_ADVANCED_FILTERS.restrictions.LOCK_DRIVER.value", {
                                            required: 'Потрібно ввести процент',
                                            min: 0
                                        })} endAdornment={"%"} helperText={"коміссія"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    {/*LEVEL 3*/}
                                    <NumberField
                                        register={register("LOCK_ADVANCED_FILTERS.restrictions.LOCK_DRIVER.value", {
                                            required: 'Потрібно ввести процент',
                                            min: 0
                                        })} endAdornment={"%"} helperText={"коміссія"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button
                        sx={{width: '200px', alignSelf: 'end', mt: 2, mb: 2}}
                        variant="contained"
                        disabled={currentCity.id === 0 || loading}
                        type="submit"
                    >Зберегти</Button>
                </Box>
            </form>
        </Box>
    );
}