import {config} from "../ApiConstants";
import {useAppDispatch} from "../hooks/appHooks";
import {AxiosError} from "axios/index";
import {setError} from "../reducers/message/globalMessageSlice";
import axiosApi from "../axiosApi/axiosConfig";
import {IDriverControlSettingsDto} from "../model/drivers/IDriverControlSettings";

export function useDriverControlController(){
    const dispatch = useAppDispatch()

    async function getCriteriaSettingsByCityId(id:number): Promise<IDriverControlSettingsDto | undefined>{
        try{
            const response = await axiosApi.get<IDriverControlSettingsDto>(config.url.DRIVER_CONTROL + '/settings/' + id)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання налаштувань.', message:(error.response?.data as any).message}))
        }
    }

    async function updateCriteriaSettings(settings: IDriverControlSettingsDto): Promise<IDriverControlSettingsDto>{
        const response = await axiosApi.patch<IDriverControlSettingsDto>(config.url.DRIVER_CONTROL + '/settings', settings)
        return response.data
        // try{
        //     await axiosApi.patch<IDriverControlSettingsDto>(config.url.DRIVER_CONTROL + '/settings', settings)
        // }catch (e : unknown) {
        //     const error = e as AxiosError
        //     dispatch(setError({title:'Помилка оновлення налаштувань.', message:(error.response?.data as any).message}))
        // }
    }

    return {
        getCriteriaSettingsByCityId,
        updateCriteriaSettings
    }

}