import React from 'react'
import {Box} from "@mui/material";
import OrdersReportTable from "../components/reports/ordersReport/OrdersReportTable";

export default function OrdersReportPage({pageHeight}:{pageHeight: number}) {

    return (
        <Box sx={{ width: '100%', height:pageHeight + 'px', typography: 'body1' }}>
            <OrdersReportTable fullHeight={pageHeight} isAutoHeight={false}/>
        </Box>
    );
}
