import {createListenerMiddleware, addListener, isRejectedWithValue} from '@reduxjs/toolkit'
import type { TypedStartListening, TypedAddListener } from '@reduxjs/toolkit'
import {AppDispatch, RootState} from "../store";
import {IErrorInfo} from "../model/message/IMessage";
import {setError} from "../reducers/message/globalMessageSlice";


export const errorsListenerMiddleware = createListenerMiddleware()

errorsListenerMiddleware.startListening({
    matcher: isRejectedWithValue, effect: async (action, listenerApi) => {
        const error = action.payload as IErrorInfo
        listenerApi.dispatch(setError({title: error.title, message: error.message}))
    }
})

export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export const startAppListening =
    errorsListenerMiddleware.startListening as AppStartListening

export const addAppListener = addListener as TypedAddListener< RootState, AppDispatch >
