import React, {useEffect} from "react";
import LoginPage from "./LoginPage";
import {useAppDispatch, useAppSelector} from "../app/hooks/appHooks";
import {Role} from "../app/model/auth/role";

type Props = {
    roles: Role[]
    children?: React.ReactNode
};

export default function ProtectedRoute(props:Props) {
    const dispatch = useAppDispatch()
    const { isAuthenticated, role } = useAppSelector(state => state.auth.state)

    return(
        <>
            { (isAuthenticated && props.roles.includes( role )) ? props.children : <LoginPage/>}
        </>
    )

}