import React from 'react'

import {Box, CssBaseline} from "@mui/material";
import MessageSnackBar from "../components/messages/MessageSnackbar";
import LoginPage from "./LoginPage";
import {Outlet} from "react-router-dom";
import {useAppSelector} from "../app/hooks/appHooks";
import ApplicationBar from "../components/appBar/ApplicationBar";

export default function Layout({appBarHeight}:{appBarHeight:number}) {
    const { isAuthenticated } = useAppSelector((state) => state.auth.state)

    return (
        <>
            <CssBaseline/>
            <MessageSnackBar/>
            <Box sx={{display: 'flex', height: '100vh', width: '100%', backgroundColor: 'grey.A100'}} aria-label="application container">
                { !isAuthenticated
                    ? <LoginPage aria-label="sigin page"/>
                    : <Box sx={{ display: 'flex', flexDirection: 'column',  width: '100%', height: '100vh' }}>
                        <ApplicationBar appBarHeight={appBarHeight} aria-label="aplication bar"/>
                        <Box sx={{ width: '100%', height: '100vh'}}>
                            <Outlet />
                        </Box>
                      </Box>
                }
            </Box>
        </>
    );
}
