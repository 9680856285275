import * as React from 'react';
import { useEffect} from 'react';

import Box from '@mui/material/Box';
import CitySelect from "../cityes/CitySelect";
import Divider from "@mui/material/Divider";
import {
    Button, FormControl, FormHelperText, Input, InputAdornment,
    Paper, Switch, Table, TableBody, TableCell, tableCellClasses,
    TableContainer, TableHead, TableRow, Typography
} from "@mui/material";
import {useDriverControlController} from "../../app/controllers/useDriverControlController";
import {useAppDispatch, useAppSelector} from "../../app/hooks/appHooks";
import {IDriverControlSettingsDto} from "../../app/model/drivers/IDriverControlSettings";
import {styled} from "@mui/material/styles";
import {setError, setOkMessage} from "../../app/reducers/message/globalMessageSlice";
import {
    Controller,
    DeepRequired,
    FieldErrorsImpl, GlobalError,
    SubmitHandler,
    useForm,
    UseFormRegisterReturn
} from "react-hook-form";
import {AxiosError} from "axios";

function styledCell() {
    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
    }));
    return StyledTableCell;
}

function NumberField(props: {
    register: UseFormRegisterReturn<string>,
    endAdornment: string,
    helperText: string
}) {
    const StyledTableCell = styledCell();

    return <StyledTableCell align="right">
        <FormControl variant="standard" sx={{m: 1, width: "10ch"}}>
            <Input
                type="number" size={"small"} defaultValue={""}
                inputProps={{step: "0.1", "aria-label": "number-field"}}
                {...props.register}
                endAdornment={<InputAdornment position="end">{props.endAdornment}</InputAdornment>}
            />
            <FormHelperText>
                {props.helperText}
            </FormHelperText>
        </FormControl>
    </StyledTableCell>;
}

function DoubleNumberField(props: {
    registerFirst: UseFormRegisterReturn<string>,
    firstEndAdornment: string
    firstHelperText: string
    registerSecond: UseFormRegisterReturn<string>
    secondEndAdornment: string
    secondHelperText: string
}) {
    const StyledTableCell = styledCell();
    return <StyledTableCell align="right">
        <Box sx={{display: "flex", justifyContent:'space-between'}}>
            <FormControl variant="standard" sx={{m: 1, width: "10ch"}}>
                <Input
                    type="number" size={"small"} defaultValue={""}
                    inputProps={{step: "0.1", "aria-label": "first-number-field"}}
                    {...props.registerFirst}
                    endAdornment={<InputAdornment position="end">{props.firstEndAdornment}</InputAdornment>}
                />
                <FormHelperText>
                    {props.firstHelperText}
                </FormHelperText>
            </FormControl>
            <FormControl variant="standard" sx={{m: 1, width: "8ch"}}>
                <Input
                    type="number" size={"small"} defaultValue={""}
                    inputProps={{step: "0.1", "aria-label": "second-number-field"}}
                    {...props.registerSecond}
                    endAdornment={<InputAdornment position="end">{props.secondEndAdornment}</InputAdornment>}
                />
                <FormHelperText>
                    {props.secondHelperText}
                </FormHelperText>
            </FormControl>
        </Box>

    </StyledTableCell>;
}

export default function DriversSettings() {
    const {currentCity } = useAppSelector((state) => state.city.state)
    const [loading, setLoading] = React.useState(false)
    const {getCriteriaSettingsByCityId, updateCriteriaSettings} = useDriverControlController()
    const dispatch = useAppDispatch()
    const {
        register,
        handleSubmit,
        reset,
        control ,
        formState: { errors, defaultValues },
    } = useForm<IDriverControlSettingsDto>({defaultValues:{
            LOCK_ADVANCED_FILTERS: {isAvailable:false, criterion:"LOCK_ADVANCED_FILTERS", cityId: currentCity.id},
            ACCEPTANCE_PERCENT: {isAvailable:false, criterion:"ACCEPTANCE_PERCENT", cityId: currentCity.id},
            AUTO_CAPTURE_OFF: {isAvailable:false, criterion:"AUTO_CAPTURE_OFF", cityId: currentCity.id},
            AUTO_CAPTURE_RADIUS: {isAvailable:false, criterion:"AUTO_CAPTURE_RADIUS", cityId: currentCity.id},
            PAYMENT_TYPE_ON: {isAvailable:false, criterion:"PAYMENT_TYPE_ON", cityId: currentCity.id},
            TELEGRAM_BOT_SUBSCRIPTION: {isAvailable:false, criterion:"PAYMENT_TYPE_ON", cityId: currentCity.id},
        }})


    const StyledTableCell = styledCell();

    useEffect(() => {
        getSettings(currentCity.id)
    },[])

    async function getSettings(cityId: number){
        setLoading(true)
        const settings = await getCriteriaSettingsByCityId(cityId)
        if(settings){
            reset(settings)
            setLoading(false)
        }
    }

    const onSubmit: SubmitHandler<IDriverControlSettingsDto> = (data) => {
        setLoading(true)
        for (let dataKey in data) {
            const restrictValue = data[dataKey as keyof IDriverControlSettingsDto];
            restrictValue.cityId = currentCity.id
        }
        updateCriteriaSettings(data).then(value => {
            reset(data)
            dispatch(setOkMessage({title:'Оновлення налаштувань збережені.', message:'Налаштування для міста ' + currentCity.name}))
            setLoading(false)
        }).catch(reason => {
            const error = reason as AxiosError
            dispatch(setError({title:'Помилка збереження налаштувань.', message:'Налаштування для міста ' + currentCity.name + '. ' + (error.response?.data as any).message}))
            setLoading(false)
        })
    }

    const cityFilterHandler = (cityId: number) => {
        getSettings(cityId)
    }

    function getTwoEmptyCells() {
        return <>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
        </>;
    }

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'grey.A100'
        }}>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <CitySelect onCityChange={cityFilterHandler} filters={undefined}/>
            </Box>
            <Divider variant="middle" sx={{mt: 2, mb: 2, ml: 8, mr: 8}}/>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Box sx={{display: 'flex', flexDirection: 'column', pl: '1%', pr: '1%', width: '100%', maxWidth: 1400}}>
                    <Typography sx={{ml: 4}}>
                        Накладання обмежень на водіїв
                    </Typography>
                    <TableContainer sx={{maxWidth: 1400}} component={Paper}>
                        <Table sx={{minWidth: 600, maxWidth: 1400}} size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" colSpan={2}>Критерій</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>Блокування водія</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>Блокування
                                        автозахоплення</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>Блокування просунутих
                                        фільтрів</StyledTableCell>
                                    <StyledTableCell align="center" colSpan={2}>Додатковий процент
                                        коміссії</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell>Назва</StyledTableCell>
                                    <StyledTableCell align="right">Активно</StyledTableCell>
                                    <StyledTableCell align="right">Значення</StyledTableCell>
                                    <StyledTableCell align="right">Вкл/Викл</StyledTableCell>
                                    <StyledTableCell align="right">Значення</StyledTableCell>
                                    <StyledTableCell align="right">Вкл/Викл</StyledTableCell>
                                    <StyledTableCell align="right">Значення</StyledTableCell>
                                    <StyledTableCell align="right">Вкл/Викл</StyledTableCell>
                                    <StyledTableCell align="right">Значення</StyledTableCell>
                                    <StyledTableCell align="right">Вкл/Викл</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* ---------------------------LOCK_ADVANCED_FILTERS --------------------------------- */}
                                <TableRow key={0} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">
                                        Блокування просунутих фільтрів
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>

                                    {/*LOCK_DRIVER*/}
                                    {getTwoEmptyCells()}

                                    {/*LOCK_AUTO_CAPTURE*/}
                                    {getTwoEmptyCells()}

                                    {/*LOCK_ADVANCED_FILTERS*/}
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="LOCK_ADVANCED_FILTERS.restrictions.LOCK_ADVANCED_FILTERS.isActive"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>

                                    {/*SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE*/}
                                    {getTwoEmptyCells()}
                                </TableRow>
                                {/*--------------------------AUTO_CAPTURE_RADIUS-----------------------------------*/}
                                <TableRow key={1} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">
                                        Радіус автозахоплення
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="AUTO_CAPTURE_RADIUS.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>

                                    {/*LOCK_DRIVER*/}
                                    <NumberField
                                        register={register("AUTO_CAPTURE_RADIUS.restrictions.LOCK_DRIVER.value", {
                                            required: 'Потрібно ввести радіус',
                                            min: 0
                                        })} endAdornment={"м."} helperText={"мин. радіус"}/>

                                    <StyledTableCell align="right">
                                        <Controller
                                            name="AUTO_CAPTURE_RADIUS.restrictions.LOCK_DRIVER.isActive"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch {...field}
                                                        checked={field.value}
                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>

                                    {/*LOCK_AUTO_CAPTURE*/}
                                    <NumberField
                                        register={register("AUTO_CAPTURE_RADIUS.restrictions.LOCK_AUTO_CAPTURE.value", {
                                            required: 'Потрібно ввести радіус',
                                            min: 0
                                        })} endAdornment={"м."} helperText={"мин. радіус"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="AUTO_CAPTURE_RADIUS.restrictions.LOCK_AUTO_CAPTURE.isActive"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>

                                    {/*LOCK_ADVANCED_FILTERS*/}
                                    <NumberField
                                        register={register("AUTO_CAPTURE_RADIUS.restrictions.LOCK_ADVANCED_FILTERS.value", {
                                            required: 'Потрібно ввести радіус',
                                            min: 0
                                        })} endAdornment={"м."} helperText={"мин. радіус"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="AUTO_CAPTURE_RADIUS.restrictions.LOCK_ADVANCED_FILTERS.isActive"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>

                                    {/*SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE*/}
                                    <DoubleNumberField
                                        registerFirst={register("AUTO_CAPTURE_RADIUS.restrictions.SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE.additionalValue", {
                                            required: 'Потрібно ввести радіус',
                                            min: 0
                                        })}
                                        firstEndAdornment={"м."}
                                        firstHelperText={"мін. радіус"}
                                        registerSecond={register("AUTO_CAPTURE_RADIUS.restrictions.SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE.value", {
                                            required: 'Потрібно ввести процент',
                                            min: 0
                                        })}
                                        secondEndAdornment={"%"}
                                        secondHelperText={"коміссія"}
                                    />
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="AUTO_CAPTURE_RADIUS.restrictions.SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE.isActive"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>

                                {/* ---------------------------ACCEPTANCE_PERCENT --------------------------------- */}
                                <TableRow key={2} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">
                                        Процент прийнятих платежів
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="ACCEPTANCE_PERCENT.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>

                                    {/*LOCK_DRIVER*/}
                                    {getTwoEmptyCells()}

                                    {/*LOCK_AUTO_CAPTURE*/}
                                    {getTwoEmptyCells()}

                                    {/*LOCK_ADVANCED_FILTERS*/}
                                    <NumberField
                                        register={register("ACCEPTANCE_PERCENT.restrictions.LOCK_ADVANCED_FILTERS.value", {
                                            required: 'Потрібно ввести процент',
                                            min: 0
                                        })} endAdornment={"%"} helperText={"мин. процент"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="ACCEPTANCE_PERCENT.restrictions.LOCK_ADVANCED_FILTERS.isActive"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>

                                    {/*SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE*/}
                                    {getTwoEmptyCells()}
                                </TableRow>

                                {/* ---------------------------AUTO_CAPTURE_OFF --------------------------------- */}
                                <TableRow key={3} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">
                                        Вимкнуте автозахоплення
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="AUTO_CAPTURE_OFF.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>

                                    {/*LOCK_DRIVER*/}
                                    {getTwoEmptyCells()}

                                    {/*LOCK_AUTO_CAPTURE*/}
                                    {getTwoEmptyCells()}

                                    {/*LOCK_ADVANCED_FILTERS*/}
                                    {getTwoEmptyCells()}

                                    {/*SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE*/}
                                    <NumberField
                                        register={register("AUTO_CAPTURE_OFF.restrictions.SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE.value", {
                                            required: 'Потрібно ввести процент',
                                            min: 0
                                        })} endAdornment={"%"} helperText={"коміссія"}/>

                                    <StyledTableCell align="right">
                                        <Controller
                                            name="AUTO_CAPTURE_OFF.restrictions.SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE.isActive"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>

                                {/* ---------------------------PAYMENT_TYPE_ON --------------------------------- */}
                                <TableRow key={4} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">
                                        Активовано фільтр "тип оплати"
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="PAYMENT_TYPE_ON.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>

                                    {/*LOCK_DRIVER*/}
                                    {getTwoEmptyCells()}

                                    {/*LOCK_AUTO_CAPTURE*/}
                                    {getTwoEmptyCells()}

                                    {/*LOCK_ADVANCED_FILTERS*/}
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="PAYMENT_TYPE_ON.restrictions.LOCK_ADVANCED_FILTERS.isActive"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>

                                    {/*SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE*/}
                                    <NumberField
                                        register={register("PAYMENT_TYPE_ON.restrictions.SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE.value", {
                                            required: 'Потрібно ввести процент',
                                            min: 0
                                        })} endAdornment={"%"} helperText={"коміссія"}/>

                                    <StyledTableCell align="right">
                                        <Controller
                                            name="PAYMENT_TYPE_ON.restrictions.SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE.isActive"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>

                                {/*--------------------------TELEGRAM_BOT_SUBSCRIPTION-----------------------------------*/}
                                <TableRow key={1} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">
                                        Контроль підписки на telegram бот
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="TELEGRAM_BOT_SUBSCRIPTION.isAvailable"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>

                                    {/*LOCK_DRIVER*/}
                                    <StyledTableCell align="right"></StyledTableCell>

                                    <StyledTableCell align="right">
                                        <Controller
                                            name="TELEGRAM_BOT_SUBSCRIPTION.restrictions.LOCK_DRIVER.isActive"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch {...field}
                                                        checked={field.value}
                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>

                                    {/*LOCK_AUTO_CAPTURE*/}
                                    <StyledTableCell align="right"></StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="TELEGRAM_BOT_SUBSCRIPTION.restrictions.LOCK_AUTO_CAPTURE.isActive"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>

                                    {/*LOCK_ADVANCED_FILTERS*/}
                                    <StyledTableCell align="right"></StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="TELEGRAM_BOT_SUBSCRIPTION.restrictions.LOCK_ADVANCED_FILTERS.isActive"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>

                                    {/*SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE*/}
                                    <NumberField
                                        register={register("TELEGRAM_BOT_SUBSCRIPTION.restrictions.SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE.value", {
                                            required: 'Потрібно ввести процент',
                                            min: 0
                                        })} endAdornment={"%"} helperText={"коміссія"}/>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="TELEGRAM_BOT_SUBSCRIPTION.restrictions.SETTING_INDIVIDUAL_COMMISSION_PERCENTAGE.isActive"
                                            control={control}
                                            defaultValue={false}
                                            render={({field}) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button
                        sx={{width: '200px', alignSelf: 'end', mt: 2, mb: 2}}
                        variant="contained"
                        disabled={currentCity.id === 0 || loading}
                        type="submit"
                    >Зберегти</Button>
                </Box>
            </form>
        </Box>
    );
}