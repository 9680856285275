import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MenuDrawerButton from "./MenuDrawerButton";
import PageName from "./PageName";
import UserIcon from "./UserIcon";


export default function ApplicationBar({appBarHeight}:{appBarHeight:number}) {
    return (
        <Box sx={{ width: '100%', height:appBarHeight + 'px'}} aria-label="app bar container" >
            <AppBar position="static" aria-label="app bar" >
                <Toolbar variant="dense" aria-label="toolbar" sx={{height:appBarHeight + 'px', minHeight:appBarHeight + 'px', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <MenuDrawerButton aria-label="menu drawer button"/>
                        <PageName/>
                    </Box>
                    <UserIcon/>
                </Toolbar>
            </AppBar>
        </Box>
    );
}