

const banks = new Map();
banks.set('Приватбанк', [410653, 413051, 414939, 414943, 414949, 414960, 414961, 414962, 414963, 417649, 424600, 424657, 434156, 440129, 440509, 440535, 440588, 458120, 458121, 458122, 462705, 462708, 473117, 473118, 473121, 476065, 516798, 516874, 516875, 516933, 516936, 521153, 521857, 523779, 524933, 532032, 532957, 535249, 536354, 545122, 545708, 545709, 552324, 557721, 558424, 676246])
banks.set('Универсал банк', [	402889, 444036, 444037, 444038, 444039, 444040, 444041, 444042, 472931, 472932])
banks.set('Райффайзен банк', [	512070, 516913, 518808, 520657, 529919, 532457, 542465, 543155, 543759, 545253, 547846, 548658, 548968, 552657, 557723, 557881, 676216, 676250, 676995, 408399, 411997, 413062, 414950, 414951, 414952, 414953, 418837, 423843, 423922, 462773, 462774, 462775, 467808, 467809, 487407])
banks.set('Ощадбанк',	[510477, 515662, 516749, 516780, 516781, 523406, 527410, 530409, 530778, 531965, 545264, 545265, 547625, 549022, 557744, 670635, 676365, 517474, 524608, 535206, 428614, 429552, 435235, 438927, 452437, 457635, 457636, 457639, 457640, 479070, 479072, 479082, 479088, 479093, 479094, 479095, 479096, 420470])
banks.set('ОТП Банк', [	510094, 516887, 522864, 528534, 533194, 546886, 547266, 676566, 406758, 406760, 406788, 410336, 410337, 411749, 416954, 416955, 436323, 471362, 479751, 510093, 406759, 530921])
banks.set('Укрсиббанк', [	511695, 516930, 522871, 523648, 529578, 533037, 535128, 535129, 535138, 557099, 557100, 558259, 676620, 407360, 407361, 407362, 407363, 407364, 407365, 407366, 407368, 417232, 434343, 535432])
banks.set('Альфа-банк', [	513479, 516799, 516800, 530565, 531854, 535196, 535555, 535557, 536364, 543259, 544179, 544904, 547994, 670516, 402808, 406622, 410232, 410242, 410243, 410244, 410245, 410246, 410247, 415931, 422605, 444424, 446670, 466065])
banks.set('А-Банк', [	516915, 535145, 558335, 530217, 544013, 521152, 670509, 404030, 432334, 432335, 432336, 432337, 432338, 432339, 432340, 537523])
banks.set('ПУМБ', [	670832, 676271, 676461, 676652, 676700, 677083, 677201, 677567, 404170, 406659, 406660, 412717, 419313, 419314, 419315, 419316, 419317, 419318, 428308, 428337, 431403, 431435, 431436, 431437, 457929, 482415, 487424, 431414, 518282, 550833, 535528])
banks.set('Креди Агриколь Банк', [	512439, 516759, 516773, 517396, 519745, 520662, 525822, 528806, 402511, 411823, 411948, 439023, 439024, 439025, 439038, 439039])
banks.set('Монобанк', [	537541, 444111])
banks.set('Восток', [	516812, 516813])
banks.set('Південий', [	402961])
banks.set('Таскомбанк', [	413418])
banks.set('Укргазбанк', [	496680])

function extractNumbers(input: string): string {
    return input.replace(/\D/g, '');
}

function getBin(cardNumber: string): number{
    const num = extractNumbers(cardNumber)
    const bin = Number(num.substring(0, 6))
    return bin
}

function bankName(bin: number){
    let bankName = ''
    banks.forEach((value, key) => {
        if(value.includes(bin)){
            bankName = key
            console.log('-- ', bankName)
            return bankName
        }
    })
    console.log('empty')
    return bankName
}

export function getBankName(cardNumber: string | undefined): string{
    if (cardNumber){
        const bin = getBin(cardNumber)
        return bankName(bin)
    }else {
        return ''
    }

}


