import * as React from 'react';
import {Avatar, Box, Typography, Container, TextField, Button} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useAppDispatch} from "../app/hooks/appHooks";
import {authObjectCreator, ILoginCredentials} from "../app/model/auth/IAuth";
import {AxiosError} from "axios";
import {login, logOut} from "../app/controllers/authController";
import {clearAuth, setAuthData} from "../app/reducers/auth/authSlice";
import {setError} from "../app/reducers/message/globalMessageSlice";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import LogoutIcon from "@mui/icons-material/Logout";



export default function LogOutPage() {
    const dispatch = useAppDispatch()


    useEffect(() => {
        logOut()
    },[])

    function onClickHandle() {
        // dispatch(clearAuth())
        document.location.href = '/';
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }} >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LogoutIcon />
                </Avatar>
                <Typography component="h1" variant="h5"> Log Out </Typography>
                <Box sx={{ mt: 1, display:'flex', flexDirection:'column', alignItems: 'center' }}>
                    <Typography>Скінчився строк дії токену,</Typography>
                    <Typography>або сталася помилка авторизації.</Typography>
                    <Button variant="contained" onClick={event => onClickHandle()}  sx={{ mt: 3, mb: 2 }} > Sign In </Button>
                </Box>
            </Box>
        </Container>
    )
}