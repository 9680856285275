import * as React from 'react';

import Box from '@mui/material/Box';
import TableEmptyOverlay from "../../overlay/TableEmptyOverlay";
import StyledOperationTable from "../StyledOperationTable";
import {useEffect, useState} from "react";
import {
    GridColDef,
    GridValueFormatterParams,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridColumnVisibilityModel,
    useGridApiRef,
    gridFilteredSortedRowEntriesSelector,
    GridToolbarExport,
    GridColumnGroupingModel,
} from "@mui/x-data-grid-pro";

import {ICompareReport} from "../../../app/model/compareReport/ICompareReport";

const currencyFormatter = new Intl.NumberFormat('ru');

const formatSum = (params: GridValueFormatterParams<number>) => {
    if (params.value === 0){
        return ''
    }
    if (params.id === 0 ){
        return currencyFormatter.format(params.value) + ' %'
    }
    return currencyFormatter.format(params.value)
}

function NoRowsOverlay() {
    return (
        <TableEmptyOverlay text={'Нема операцій'}/>
    );
}

interface IProps {
    isAutoHeight:boolean
    operations: ICompareReport[]
    isPending:boolean
}

export default function CompareReportTable({
                                            operations,
                                            isPending,
                                            isAutoHeight,
                                        }: IProps) {
    const [columnVisibleModel, setColumnVisibleModel] = useState<GridColumnVisibilityModel>({
        id:false,
    })
    const [pinnedSumRow, setPinnedSumRow] = useState<any>({id:0})
    const apiRef = useGridApiRef();

    useEffect(() => {
        calculateCommonRow()
    }, [operations]);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'id', flex: 1, minWidth: 60, maxWidth:150, type: 'number', },
        { field: 'amountOrdersExecuted', headerName: 'Виконано замовлень', flex: 1, minWidth: 180, maxWidth: 200, type: 'number', valueFormatter: formatSum },
        { field: 'ordersSum', headerName: 'Сума замовлень', flex: 1, minWidth: 180, maxWidth: 250, type: 'number', valueFormatter: formatSum },
        { field: 'averageSum', headerName: 'Середній чек', flex: 1, minWidth: 100, maxWidth: 250, type: 'number', valueFormatter: formatSum},
    ];

    const calculateCommonRow = () => {
        const filteredRows = gridFilteredSortedRowEntriesSelector(apiRef);
        let amountOrdersExecutedDivPercent = 0
        let ordersSumDivPercent = 0
        let averageSumDivPercent = 0

        if (filteredRows.length === 2){
            const firstAmountOrdersExecuted = filteredRows[0].model['amountOrdersExecuted']
            const secondAmountOrdersExecuted = filteredRows[1].model['amountOrdersExecuted']
            amountOrdersExecutedDivPercent = firstAmountOrdersExecuted > 0 && secondAmountOrdersExecuted > 0 ? ((firstAmountOrdersExecuted - secondAmountOrdersExecuted) / firstAmountOrdersExecuted) * 100 : 0

            const firstOrdersSum = filteredRows[0].model['ordersSum']
            const secondOrdersSum = filteredRows[1].model['ordersSum']
            ordersSumDivPercent = firstOrdersSum > 0 && secondOrdersSum > 0 ? ((firstOrdersSum - secondOrdersSum) / firstOrdersSum) * 100  : 0

            const firstAverageSum = filteredRows[0].model['averageSum']
            const secondAverageSum = filteredRows[1].model['averageSum']
            averageSumDivPercent = firstAverageSum > 0 && secondAverageSum > 0 ? ((firstAverageSum - secondAverageSum) / firstAverageSum) * 100 : 0
        }

        const pinnedRowSummeData = Object.assign({id:0},
            {
                amountOrdersExecuted: amountOrdersExecutedDivPercent,
                ordersSum: ordersSumDivPercent,
                averageSum: averageSumDivPercent,
            } );
        setPinnedSumRow(pinnedRowSummeData)
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton/>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{width: '100%', height: '100%'}}>
            <StyledOperationTable
                apiRef={apiRef}
                rows={ operations }
                columns={columns}
                loading={isPending}
                density="compact"
                autoHeight={isAutoHeight}
                pinnedRows={ { bottom:[ pinnedSumRow]} }
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'asc' }],
                    },
                }}
                hideFooter={true}
                hideFooterPagination={true}
                hideFooterSelectedRowCount
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                    toolbar: CustomToolbar,
                }}
                columnVisibilityModel={columnVisibleModel}
                onColumnVisibilityModelChange={setColumnVisibleModel}
            />
        </Box>
    );
}