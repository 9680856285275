import * as React from 'react';
import {Avatar, Box, Typography, Container, TextField} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useAppDispatch} from "../app/hooks/appHooks";
import {authObjectCreator, ILoginCredentials} from "../app/model/auth/IAuth";
import {AxiosError} from "axios";
import {login} from "../app/controllers/authController";
import {setAuthData} from "../app/reducers/auth/authSlice";
import {setError} from "../app/reducers/message/globalMessageSlice";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";


export default function LoginPage() {
    const [isPending, setIsPending] = useState(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            const authData = authObjectCreator(token);
            dispatch(setAuthData(authData));
        }
    },[])

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        setIsPending(true)
        event.preventDefault()
        const formData = new FormData(event.currentTarget)
        const username: any = formData.get('username')
        const password: any =  formData.get('password')
        if (username !== null && password !== null ){
            try{
                const credentials: ILoginCredentials = {login: username, password: password }
                const token = await login(credentials);
                const authData = authObjectCreator(token);
                await dispatch(setAuthData(authData));
                setIsPending(false)
            } catch (e : unknown){
                const error = e as AxiosError
                console.error(error.message)
                if (error.response?.status === 403){
                    dispatch(setError({title: 'Помилка авторізації.', message: error.message}))
                }else{
                    dispatch(setError({title: 'Сталася помилка при авторізації.', message: error.message}))
                }
                setIsPending(false)
            }
        }
    }

    return (
            <Container component="main" maxWidth="xs">
                {process.env.NODE_ENV !== 'production' && <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>}
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }} >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5"> Sign in </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField margin="normal" required fullWidth disabled={isPending} id="username" label="username" name="username"
                                   autoFocus />
                        <TextField margin="normal" required fullWidth disabled={isPending} name="password" label="Password" type="password" id="password"
                            autoComplete="current-password" />
                        <LoadingButton type="submit" fullWidth variant="contained" loading={isPending} sx={{ mt: 3, mb: 2 }} > Sign In </LoadingButton>
                    </Box>
                </Box>
            </Container>
    )
}