import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {Typography} from "@mui/material";

function PageName() {
    let location = useLocation();
    const [pageName, setPageName] = useState<string>('')

    useEffect(() => {
        const name: string | null = location.state?.pathName
        if (name !== null){
            setPageName(name)
        }

    }, [location])
    return (
        <Typography sx={{whiteSpace:'nowrap'}}>{pageName}</Typography>
    )
}

export default PageName;