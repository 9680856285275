import {combineReducers, configureStore} from "@reduxjs/toolkit";
import authReducer from "./reducers/auth/authSlice"
import cityReducer from "./reducers/city/citySlice"
import globalMessageSlice from "./reducers/message/globalMessageSlice";

import {errorsListenerMiddleware} from "./middleware/errorsListenerMiddleware";


const rootReducer = combineReducers({
    auth: authReducer,
    globalMessage: globalMessageSlice,
    city: cityReducer
})


const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .prepend(errorsListenerMiddleware.middleware)
})

export const setupStore = () => {
    return store
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']