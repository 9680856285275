import React from "react";
import {isRouteErrorResponse, useRouteError} from "react-router-dom";

export default function ErrorPage(){
    // you don't need to explicitly set error to `unknown`
    const error = useRouteError();
    let errorMessage: string;

    if (isRouteErrorResponse(error)) {
        // error is type `ErrorResponse`
        errorMessage = error.data?.message || error.statusText;
    } else if (error instanceof Error) {
        errorMessage = error.message;
    } else if (typeof error === 'string') {
        errorMessage = error;
    } else {
        console.error(error);
        errorMessage = 'Unknown error';
    }

    return (
        <div id='error-page'>
            <h1 >Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p >
                <i>{errorMessage}</i>
            </p>
        </div>
    );
}