import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { IAuthData, initialAuthState} from "../../model/auth/IAuth";
import {RootState} from "../../store";
import {ICities, ICity} from "../../model/city/ICity";

interface ICityState {
    state: ICities
}

const initialState: ICityState = {
    state:{currentCity:{id:0, name:'Не обрано'}, cities:[]}
}

const citySlice = createSlice({
    name: 'city',
    initialState,
    reducers: {
        setCities: (state, action:PayloadAction<ICity[]>) => {
            state.state.cities = action.payload
        },
        setCurrentCity: (state, action:PayloadAction<ICity>) => {
            state.state.currentCity = action.payload
        }
    }
});

export const {
    setCities,
    setCurrentCity,
} = citySlice.actions

export const city = (state: RootState) => state.city

export default citySlice.reducer;