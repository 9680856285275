import React, {useState} from 'react'
import {Box, Tab} from "@mui/material";
import DriversTable from "../components/drivers/DriversTable";

export default function DriversPage({pageHeight}:{pageHeight: number}) {

    return (
        <Box sx={{ width: '100%', height:pageHeight + 'px', typography: 'body1' }}>
            <DriversTable fullHeight={pageHeight} isAutoHeight={false}/>
        </Box>
    );
}
