import {config} from "../ApiConstants";
import {useAppDispatch} from "../hooks/appHooks";
import {AxiosError} from "axios/index";
import {setError} from "../reducers/message/globalMessageSlice";
import axiosApi from "../axiosApi/axiosConfig";
import {IDriverPage} from "../model/drivers/IDriver";
import {IRequestQueryMultiple} from "../model/pageRequestQuery/IRequestQueryMultiple";
// import {ILockAdvancedFilters} from "../model/drivers/ILockAdvancedFilters";

export function useDriversController(){
    const dispatch = useAppDispatch()

    async function getDriversPage(queryParams:IRequestQueryMultiple): Promise<IDriverPage>{
        try{
            const response = await axiosApi.post<IDriverPage>(config.url.DRIVERS + '/page', queryParams)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання водіїв.', message:(error.response?.data as any).message}))
            return {rows: [], totalRows: 0}
        }
    }



    return {
        getDriversPage,
    }

}