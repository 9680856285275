import React, {useState} from 'react'
import {Box, Tab} from "@mui/material";
import DriversTable from "../components/drivers/DriversTable";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import DriversSettings from "../components/drivers/DriversSettings";
import CitiesSettings from "../components/cityes/CitiesSettings";
import DriverBonusesSettings from "../components/drivers/DriverBonusesSettings";

export default function SettingsPage({pageHeight}:{pageHeight: number}) {
    const [tabIndex, setTabIndex] = useState<string>("cities")
    const tabHeight: number = 48
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabIndex(newValue)
    };

    return (
        <Box sx={{ width: '100%', height:pageHeight + 'px', typography: 'body1' }}>
            <TabContext value={tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', ml:3, mr:3, height: tabHeight + 'px' }} aria-label={'test'}>
                    <TabList onChange={handleChange} aria-label="settings tab" textColor="secondary"
                             indicatorColor="secondary">
                        <Tab label={'Міста'} value="cities" sx={{color:'primary.main'}} />
                        <Tab label={'Обмеження водіїв'} value="driversControl" sx={{color:'primary.main'}} />
                        <Tab label={'Бонуси для водіїв'} value="driverBonusControl" sx={{color:'primary.main'}} />
                    </TabList>
                </Box>
                <TabPanel value="cities" sx={{p:0}}>
                    <CitiesSettings/>
                </TabPanel>
                <TabPanel value="driversControl" sx={{p:0}}>
                    <DriversSettings/>
                </TabPanel>
                <TabPanel value="driverBonusControl" sx={{p:0}}>
                    <DriverBonusesSettings/>
                </TabPanel>
            </TabContext>
        </Box>
    );
}
