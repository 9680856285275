
export const converterStringTimestampToDate = (timestamp: string | undefined): Date | undefined => {
    if (timestamp === undefined || timestamp === null || timestamp.length === 0 || timestamp === "0" ){
        return undefined
    }
    return new Date(Number.parseInt(timestamp))
}

export const converterDateToStringTimestamp = (date: Date | undefined): string | undefined => {
    if (date === undefined ){
        return undefined
    }
    return (new Date(date).getTime()).toString()
}

export function dateFormatTimeLast(formatDate: Date | undefined):string {
    if (formatDate === null || formatDate === undefined) {
        return ''
    }
    const date = new Date(formatDate)
    const valueFormatted = date.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        + '-' + (date.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        + '-' + date.getFullYear()
        + ' ' + date.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        + ':' + date.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        + ':' + date.getSeconds().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
    return valueFormatted
}

export function dateFormatTimeFirst(formatDate: Date | undefined):string {
    if (formatDate === null || formatDate === undefined) {
        return ''
    }
    const date = new Date(formatDate)
    const valueFormatted =
        date.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        + ':' + date.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        + ':' + date.getSeconds().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        + ' ' + date.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        + '-' + (date.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        + '-' + date.getFullYear()
    return valueFormatted
}

export function dateFormatDataOnly(formatDate: Date | undefined):string {
    if (formatDate === null || formatDate === undefined) {
        return ''
    }
    const date = new Date(formatDate)
    const valueFormatted =
        date.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        + '-' + (date.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        + '-' + date.getFullYear()
    return valueFormatted
}