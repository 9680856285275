import React from 'react'
import {Box} from "@mui/material";
import FundsTransferTable from "../components/fundsTransfer/FundsTransferTable";

export default function TransferFundsReportPage({pageHeight}:{pageHeight: number}) {

    return (
        <Box sx={{ width: '100%', height:pageHeight + 'px', typography: 'body1' }}>
            <FundsTransferTable fullHeight={pageHeight} isAutoHeight={false}/>
        </Box>
    );
}
