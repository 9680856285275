import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import MenuList from "./MenuList";


export default function MenuDrawerButton() {
    const [state, setState] = React.useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setState(open);
    }

    return (
        <div>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{mr: 1}}
                        onClick={toggleDrawer(true)}>
                <MenuIcon/>
            </IconButton>
            <Drawer anchor={'left'} open={state} onClose={toggleDrawer(false)} aria-label="drawer-menu">
                <MenuList onSelect={toggleDrawer(false)}/>
            </Drawer>
        </div>
    );
}