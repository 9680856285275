import * as React from 'react';

import Box from '@mui/material/Box';
import {
    converterStringTimestampToDate,
    dateFormatTimeLast
} from "../../app/utils/DataUtil";
import TableEmptyOverlay from "../overlay/TableEmptyOverlay";
import {useEffect, useState} from "react";
import {
    GridColDef,
    GridFilterModel,
    GridValueFormatterParams,
    GridValueGetterParams,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridColumnVisibilityModel,
    useGridApiRef,
    gridFilteredSortedRowEntriesSelector,
    GridToolbarExport, GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import {Button, IconButton, Link} from "@mui/material";
import {IRequestQueryMultiple} from "../../app/model/pageRequestQuery/IRequestQueryMultiple";
import {IFilters} from "../../app/model/pageRequestQuery/IFilters";
import DateTimeRange from "../dateTimeRange/DateTimeRange";
import dayjs from "dayjs";
import {ITransferFoundsOperation} from "../../app/model/transferFoundsOperation/ITransferFoundsOperation";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro/DataGridPro/DataGridPro";
import {useTransferFoundsOperationController} from "../../app/controllers/useTransferFoundsOperationController";
import SyncIcon from "@mui/icons-material/Sync";

const dateTimeConverter = (params: GridValueGetterParams<any, any>):Date | undefined => {

    return converterStringTimestampToDate(params.row[params.field])
}

const currencyFormatter = new Intl.NumberFormat('ru');

const formatSum = (params: GridValueFormatterParams<number>) => {
    if (params.value === 0){
        return ''
    }
    const sum = params.value / 100.0
    return currencyFormatter.format(sum)
}

const renderLink = (params: GridRenderCellParams<any, string>) => {
    const link = "https://admin.ontaxi.com.ua/drivers/" + params.value + "?tab=3"
    return <Link href={link} underline="hover" target="_blank">
        {params.value}
    </Link>
}


const formatDate = (params: GridValueFormatterParams<Date>) => {
    return dateFormatTimeLast(params.value)
}


function NoRowsOverlay() {
    return (
        <TableEmptyOverlay text={'Нема операцій'}/>
    );
}

interface IProps {
    isAutoHeight:boolean
    fullHeight:number,
}

export default function FundsTransferTable({isAutoHeight, fullHeight }: IProps) {
    const [isPending, setPending] = useState(false)
    const [operations, setOperations] = useState<ITransferFoundsOperation[]>([])
    const {getOperations} = useTransferFoundsOperationController()
    const [lastQuery, setLastQuery] = useState<IRequestQueryMultiple | undefined>(undefined)
    const [dateRangeFilter, setDateRangeFilter] = useState<IFilters>({
        filters:[
            {field:'createDateTime', operator:'after', value:dayjs(new Date()).subtract(3, "day").set('hour',0).set('minute',0).format('YYYY-MM-DDTHH:mm')},
            {field:'createDateTime', operator:'before', value:dayjs(new Date()).set('hour',23).set('minute',59).format('YYYY-MM-DDTHH:mm')}
        ],  operator:'and'
    })
    const [columnVisibleModel, setColumnVisibleModel] = useState<GridColumnVisibilityModel>({id:false,})
    const [pinnedSumRow, setPinnedSumRow] = useState<any>({id:0})
    const apiRef = useGridApiRef();

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'id', flex: 1, minWidth: 60, maxWidth:150, type: 'number', },
        { field: 'driverId', headerName: 'id водія що переказує', flex: 1, minWidth: 60, maxWidth:200, type: 'string', renderCell: renderLink},
        { field: 'driverName', headerName: 'Ім`я що переказує', flex: 1, minWidth: 150, maxWidth:200, type: 'string', },
        { field: 'driverPhone', headerName: 'Телефон що переказує', flex: 1, minWidth: 150, maxWidth:200, type: 'string', },
        { field: 'depositedAccountId', headerName: 'id водія що отримує', flex: 1, minWidth: 60, maxWidth:200, type: 'string', renderCell: renderLink },
        { field: 'depositedDriverName', headerName: 'Ім`я що отримує', flex: 1, minWidth: 150, maxWidth:200, type: 'string', },
        { field: 'depositedDriverPhone', headerName: 'Телефон що отримує', flex: 1, minWidth: 150, maxWidth:200, type: 'string', },
        { field: 'sum', headerName: 'Повна сума', flex: 1, minWidth: 150, maxWidth: 160, type: 'number', valueFormatter: formatSum },
        { field: 'createDateTime', headerName: 'Дата створення', flex: 1, minWidth: 155, maxWidth: 200, type: 'dateTime', valueGetter: dateTimeConverter, valueFormatter: formatDate },
    ];

    useEffect(() => {
        fetchOperations({filter:dateRangeFilter})
    }, []);

    useEffect(() => {
        calculateCommonRow()
    }, [operations]);

    async function fetchOperations(query: IRequestQueryMultiple){
        setPending(true)
        const operationsPage = await getOperations(query);
        setOperations(operationsPage.operations)
        setPending(false)
        setLastQuery(query)
    }

    const updateOperations = () => {
        if (lastQuery !== undefined){
            fetchOperations(lastQuery)
        }
    }

    const onFilterChange = (model: GridFilterModel) => {
        calculateCommonRow()
    };

    const dateRangeFilterHandler = (dateRangeFilter: IFilters) => {
        setDateRangeFilter(dateRangeFilter)
        fetchOperations({filter:dateRangeFilter})
    }

    const calculateCommonRow = () => {
        const filteredRows = gridFilteredSortedRowEntriesSelector(apiRef);
        let sum = 0
        let amount = 0
        let commission = 0
        filteredRows.forEach(value => {
            const tmpSum = value.model['sum']
            sum += tmpSum
            const tmpAmount = value.model['amount']
            amount += tmpAmount
            const tmpCommission = value.model['commission']
            commission += tmpCommission
        })
        const pinnedRowSummeData = Object.assign({id:0}, {sum: sum, amount: amount, commission:commission} );
        setPinnedSumRow(pinnedRowSummeData)
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{mt:1}}>
                <IconButton aria-label="update" sx={{ml:1}} onClick={updateOperations}>
                    <SyncIcon />
                </IconButton>
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton />
                <GridToolbarExport />
                <DateTimeRange filters={dateRangeFilter} fieldName={"createDateTime"} onTimeRangeChange={dateRangeFilterHandler}/>
            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{width: '100%', height: fullHeight + 'px'}}>
            <DataGrid
                apiRef={apiRef}
                rows={ operations }
                columns={columns}
                loading={isPending}
                density="compact"
                autoHeight={isAutoHeight}
                pinnedRows={{bottom:[pinnedSumRow]}}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'asc' }],
                    },
                }}
                hideFooter={true}
                hideFooterPagination={true}
                hideFooterSelectedRowCount
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                    toolbar: CustomToolbar,
                }}
                onFilterModelChange={(newFilterModel) => onFilterChange(newFilterModel)}
                columnVisibilityModel={columnVisibleModel}
                onColumnVisibilityModelChange={setColumnVisibleModel}
            />
        </Box>
    );
}