import * as React from 'react';

import Box from '@mui/material/Box';
import {converterStringTimestampToDate, dateFormatDataOnly} from "../../../app/utils/DataUtil";
import TableEmptyOverlay from "../../overlay/TableEmptyOverlay";
import StyledOperationTable from "../StyledOperationTable";
import {useEffect, useState} from "react";
import {
    GridColDef,
    GridRowClassNameParams,
    GridValueFormatterParams,
    GridValueGetterParams,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridColumnVisibilityModel,
    useGridApiRef,
    gridFilteredSortedRowEntriesSelector,
    GridToolbarExport,
} from "@mui/x-data-grid-pro";
import {Button, ButtonGroup, IconButton} from "@mui/material";
import {IRequestQueryMultiple} from "../../../app/model/pageRequestQuery/IRequestQueryMultiple";
import {IFilters} from "../../../app/model/pageRequestQuery/IFilters";
import DateTimeRange from "../../dateTimeRange/DateTimeRange";
import dayjs from "dayjs";
import {IFinReport} from "../../../app/model/finReport/IFinReport";
import CitySelect from "../../cityes/CitySelect";
import {useFinReportController} from "../../../app/controllers/useFinReportController";
import {IFilter} from "../../../app/model/pageRequestQuery/IFilter";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const dateTimeConverter = (params: GridValueGetterParams<any, any>):Date | undefined => {
    return converterStringTimestampToDate(params.row.date)
}

const currencyFormatter = new Intl.NumberFormat('ru');

const formatSum = (params: GridValueFormatterParams<number>) => {
    if (params.value === 0){
        return ''
    }
    return currencyFormatter.format(params.value)
}


const formatDate = (params: GridValueFormatterParams<Date>) => {
    return dateFormatDataOnly(params.value)
}

function NoRowsOverlay() {
    return (
        <TableEmptyOverlay text={'Нема операцій'}/>
    );
}

function styles(params: GridRowClassNameParams<any>):string{
    const operation = params.row as IFinReport
    if (operation.isEmptyCommissionPresent) {
        return 'row-theme--error'
    }
    return ''
}

interface IProps {
    isAutoHeight:boolean
    fullHeight:number
}

export default function FinReportTable({isAutoHeight, fullHeight}: IProps) {
    const [isPending, setPending] = useState(false)
    const [report, setReport] = useState<IFinReport[]>([])
    const {getReportByDateRange, getReportByDays} = useFinReportController()
    const [isByDays, setIsByDays] = useState(false)
    const [lastQuery, setLastQuery] = useState<IRequestQueryMultiple | undefined>(undefined)
    const [dateRangeFilter, setRangeFilter] = useState<IFilters>({
        filters:[
            {field:'created', operator:'after', value:dayjs(new Date()).set('hour',0).set('minute',0).format('YYYY-MM-DDTHH:mm')},
            {field:'created', operator:'before', value:dayjs(new Date()).set('hour',23).set('minute',59).format('YYYY-MM-DDTHH:mm')},
        ],  operator:'and'
    })
    const [columnVisibleModel, setColumnVisibleModel] = useState<GridColumnVisibilityModel>({id:false})
    const [pinnedSumRow, setPinnedSumRow] = useState<any>({id:0})
    const apiRef = useGridApiRef();

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'id', flex: 1, minWidth: 60, maxWidth:150, type: 'number', },
        { field: 'data', headerName: 'Дата', flex: 0.5, minWidth: 100, maxWidth: 130, type: 'date', valueGetter: dateTimeConverter, valueFormatter: formatDate },
        { field: 'sumExecutedReports', headerName: 'Сума', flex: 1, minWidth: 120, maxWidth: 200, type: 'number', valueFormatter: formatSum },
        { field: 'amountExecutedReports', headerName: 'Виконано', flex: 1, minWidth: 120, maxWidth: 200, type: 'number', valueFormatter: formatSum },
        { field: 'amountOfflineOrdersAccepted', headerName: 'Офлайн прийнято', flex: 1, minWidth: 140, maxWidth: 280, type: 'number', valueFormatter: formatSum },
        { field: 'amountOfflineOrdersExecuted', headerName: 'Офлайн виконано', flex: 1, minWidth: 140, maxWidth: 280, type: 'number', valueFormatter: formatSum},
        { field: 'averageCheckSum', headerName: 'Середній чек', flex: 0.7, minWidth: 140, maxWidth:250, type: 'number', valueFormatter: formatSum },
        { field: 'commissionPerOrdersSum', headerName: 'Комісія', flex: 1, minWidth: 120, maxWidth:250, type: 'number', valueFormatter: formatSum  },
        { field: 'commissionPercentage', headerName: 'Процент комісії', flex: 0.7, minWidth: 170, maxWidth:250, type: 'number', valueFormatter: formatSum},
        { field: 'promoCodesSum', headerName: 'Сума промокодів', flex: 1, minWidth: 180, maxWidth: 200, type: 'number', valueFormatter: formatSum },
        { field: 'paymentsFromServiceSum', headerName: 'Доплати від сервіса', flex: 1, minWidth: 200, maxWidth: 230, type: 'number', valueFormatter: formatSum },
    ];

    useEffect(() => {
        if (lastQuery){
            fetchOperations(lastQuery)
        }
    }, [isByDays]);

    useEffect(() => {
        fetchOperations({filter: dateRangeFilter})
    }, []);

    useEffect(() => {
        calculateCommonRow()
    }, [report]);

    async function fetchOperations(query: IRequestQueryMultiple){
        setPending(true)
        if (isByDays){
            await fetchByDays(query)
        }else{
            await fetchByDateRange(query)
        }
        setPending(false)
        setLastQuery(query)
    }

    async function fetchByDays(query: IRequestQueryMultiple) {
        const reportsPage = await getReportByDays(query);
        setReport(reportsPage.rows)
    }

    async function fetchByDateRange(query: IRequestQueryMultiple) {
        const report = await getReportByDateRange(query);
        setReport(report)
    }

    const rangeFilterHandler = (dateRangeFilter: IFilters) => {
        setRangeFilter(dateRangeFilter)
        fetchOperations({filter: dateRangeFilter})
    }

    function insertCityFilter(cityId: number, filter: IFilters): IFilters {
        if (filter && filter.filters.length > 0) {
            const newFilters = [...filter.filters]
            if (cityId !== 0) {
                const cityFilter = newFilters.filter(value => value.field === 'cityId');
                if (cityFilter.length === 0) {
                    const filter: IFilter = {field: 'cityId', operator: '=', value: cityId}
                    newFilters.push(filter)
                } else {
                    cityFilter[0].value = cityId
                }
                return {filters: newFilters, operator: 'and'}
            } else {
                let noCityFilter = newFilters.filter(value => value.field !== 'cityId');
                return {filters: noCityFilter, operator: 'and'}
            }
        } else {
            console.error("Incorrect input filter value for date range")
        }
        return {filters:[], operator: 'and'}
    }

    const cityFilterHandler = (cityId: number) => {
        const filter = insertCityFilter(cityId, dateRangeFilter);
        setRangeFilter(filter)

        fetchOperations({filter: filter})
    }

    const calculateCommonRow = () => {
        const filteredRows = gridFilteredSortedRowEntriesSelector(apiRef);
        let amountExecutedReports = 0
        let amountOfflineOrdersAccepted = 0
        let amountOfflineOrdersExecuted = 0
        let averageCheckSum = 0
        let commissionPerOrdersSum = 0
        let commissionPercentage = 0
        let promoCodesSum = 0
        let paymentsFromServiceSum = 0

        filteredRows.forEach(value => {
            amountExecutedReports += value.model['amountExecutedReports']
            amountOfflineOrdersAccepted += value.model['amountOfflineOrdersAccepted']
            amountOfflineOrdersExecuted += value.model['amountOfflineOrdersExecuted']
            averageCheckSum += value.model['averageCheckSum']
            commissionPerOrdersSum += value.model['commissionPerOrdersSum']
            commissionPercentage += value.model['commissionPercentage']
            promoCodesSum += value.model['promoCodesSum']
            paymentsFromServiceSum += value.model['paymentsFromServiceSum']
        })
        const pinnedRowSummeData = Object.assign({id:0},
            {
                amountExecutedReports: amountExecutedReports,
                amountOfflineOrdersAccepted: amountOfflineOrdersAccepted,
                amountOfflineOrdersExecuted: amountOfflineOrdersExecuted,
                averageCheckSum: averageCheckSum,
                commissionPerOrdersSum: commissionPerOrdersSum,
                commissionPercentage: commissionPercentage,
                promoCodesSum: promoCodesSum,
                paymentsFromServiceSum: paymentsFromServiceSum
            } );
        setPinnedSumRow(pinnedRowSummeData)
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{mt:1, justifyContent:'space-between'}}>
                <Box sx={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                    <GridToolbarColumnsButton/>
                    <GridToolbarFilterButton/>
                    <GridToolbarExport />
                    <DateTimeRange fieldName={"created"} filters={dateRangeFilter} onTimeRangeChange={rangeFilterHandler}/>
                    <CitySelect onCityChange={cityFilterHandler} filters={dateRangeFilter}/>
                </Box>

                <ButtonGroup variant="outlined" size={'small'} aria-label="outlined button group" sx={{mt:1, mb:0, height:'30px'}}>
                    <Button variant={isByDays === true ? 'contained': 'outlined'}
                            startIcon={isByDays === true ? <CheckCircleIcon/>: <CancelIcon/>}
                            onClick={event => setIsByDays(prevState => !prevState)}>
                        по дням
                    </Button>
                </ButtonGroup>
            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{width: '100%', height: fullHeight + 'px'}}>
            <StyledOperationTable
                apiRef={apiRef}
                rows={ report }
                columns={columns}
                loading={isPending}
                density="compact"
                autoHeight={isAutoHeight}
                pinnedRows={ isByDays ? { bottom:[ pinnedSumRow]}: undefined}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'asc' }],
                    },
                }}
                hideFooter={true}
                hideFooterPagination={true}
                hideFooterSelectedRowCount
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                    toolbar: CustomToolbar,
                }}
                columnVisibilityModel={columnVisibleModel}
                onColumnVisibilityModelChange={setColumnVisibleModel}
                getRowClassName={styles}
            />
        </Box>
    );
}