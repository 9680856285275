import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IGlobalMessageSnackbar} from "../../model/message/IMessage";
import {RootState} from "../../store";

const initialState: IGlobalMessageSnackbar = {
    title:'',
    message:'',
    isPresence: false,
    messageType: undefined
};

export const globalMessageSlice = createSlice({
    name: 'globalMessage',
    initialState,
    reducers: {
        clearMessage: (state) => {
            state.title = ""
            state.message = ""
            state.messageType = undefined
            state.isPresence = false
        },
        setError:(state, action: PayloadAction<{title: string, message: string}>) => {
            state.title = action.payload.title
            state.message = action.payload.message
            state.messageType = "error"
            state.isPresence = true
        },
        setWarning:(state, action: PayloadAction<{title: string, message: string}>) => {
            state.title = action.payload.title
            state.message = action.payload.message
            state.messageType = "warning"
            state.isPresence = true
        },
        setOkMessage:(state, action) => {
            state.title = action.payload.title
            state.message = action.payload.message
            state.messageType = "success"
            state.isPresence = true
        }

    },

});

export const { clearMessage, setError, setWarning, setOkMessage } = globalMessageSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const globalMessage = (state: RootState) => state.globalMessage

export default globalMessageSlice.reducer