import React from 'react'
import {Box} from "@mui/material";
import FraudReportTable from "../components/reports/fraudReport/FraudReportTable";

export default function FraudReportPage({pageHeight}:{pageHeight: number}) {

    return (
        <Box sx={{ width: '100%', height:pageHeight + 'px', typography: 'body1' }}>
            <FraudReportTable fullHeight={pageHeight} isAutoHeight={false}/>
        </Box>
    );
}
