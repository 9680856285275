import {config} from "../ApiConstants";
// import axios from "axios";
import {useAppDispatch} from "../hooks/appHooks";
import {AxiosError} from "axios/index";
import {setError} from "../reducers/message/globalMessageSlice";
import {IRequestQueryMultiple} from "../model/pageRequestQuery/IRequestQueryMultiple";
import axiosApi from "../axiosApi/axiosConfig";
import {ICompareReport} from "../model/compareReport/ICompareReport";

export function useCompareReportController(){
    const dispatch = useAppDispatch()

    async function getReportByDateRange(queryParams:IRequestQueryMultiple): Promise<ICompareReport | undefined>{
        try{
            const response = await axiosApi.post<ICompareReport>(config.url.COMPARE_REPORT + '/dataTimePeriod', queryParams)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання звіту.', message:(error.response?.data as any).message}))
            return undefined
        }
    }


    return {
        getReportByDateRange,
    }

}