import React from 'react';
import Box from "@mui/material/Box";

interface IProps{
    text:string
}

function TableEmptyOverlay(props: IProps) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', backgroundColor:'grey.A100' }}>
            {props.text}
        </Box>
    );
}

export default TableEmptyOverlay;