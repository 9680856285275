import React, {useEffect, useState} from 'react';

import {IFinReport} from "../../../app/model/finReport/IFinReport";
import {IRequestQueryMultiple} from "../../../app/model/pageRequestQuery/IRequestQueryMultiple";
import {useFinReportController} from "../../../app/controllers/useFinReportController";
import {Box, Button} from "@mui/material";
import DateTimeRange from "../../dateTimeRange/DateTimeRange";
import CitySelect from "../../cityes/CitySelect";
import {IFilters} from "../../../app/model/pageRequestQuery/IFilters";
import dayjs, {ManipulateType} from "dayjs";
import CompareReportTable from "./CompareReportTable";
import {useCompareReportController} from "../../../app/controllers/useCompareReportController";
import {IFilter} from "../../../app/model/pageRequestQuery/IFilter";
import {ICompareReport} from "../../../app/model/compareReport/ICompareReport";
import {useOrderController} from "../../../app/controllers/useOrderController";



function CompareReportByRange() {
    const [isPending, setPending] = useState(false)
    const [report, setReport] = useState<ICompareReport[]>([])
    const [lastQuery, setLastQuery] = useState<IRequestQueryMultiple | undefined>(undefined)
    const {getReportByDateRange} = useCompareReportController()

    const [dateRangeFilter, setRangeFilter] = useState<IFilters>({
        filters:[
            {field:'created', operator:'after', value:dayjs(new Date()).set('hour',0).set('minute',0).format('YYYY-MM-DDTHH:mm')},
            {field:'created', operator:'before', value:dayjs(new Date()).set('hour',23).set('minute',59).format('YYYY-MM-DDTHH:mm')},
        ],
        operator:'and'
    })

    const [secondDateRangeFilter, setSecondRangeFilter] = useState<IFilters>({
        filters:[
            {field:'created', operator:'after', value:dayjs(new Date()).set('hour',0).set('minute',0).subtract(1, 'day').format('YYYY-MM-DDTHH:mm')},
            {field:'created', operator:'before', value:dayjs(new Date()).set('hour',23).set('minute',59).subtract(1, 'day').format('YYYY-MM-DDTHH:mm')},
        ],
        operator:'and'
    })

    useEffect(() => {
        fetchOperations({filter: dateRangeFilter}, {filter: secondDateRangeFilter})
    }, []);

    async function fetchOperations(firstQuery: IRequestQueryMultiple, secondQuery: IRequestQueryMultiple){
        setPending(true)
        const newReport = await fetchFirstByDateRange(firstQuery, report);
        const resultReport = await fetchSecondByDateRange(secondQuery, newReport)

        setReport(resultReport)
        setPending(false)
    }

    async function fetchFirstByDateRange(query: IRequestQueryMultiple, report: ICompareReport[]): Promise<ICompareReport[]> {
        const firstReport = await getReportByDateRange(query);
        if(firstReport){
            firstReport.id = 1
            const newReport = [...report]
            const filteredReport = newReport.filter(value => value.id !== 1);
            filteredReport.push(firstReport)

            return filteredReport;
        }
        return report;
    }

    async function fetchSecondByDateRange(query: IRequestQueryMultiple, report: ICompareReport[]): Promise<ICompareReport[]> {
        const secondReport = await getReportByDateRange(query);
        if(secondReport){
            secondReport.id = 2
            const newReport = [...report]
            const filteredReport = newReport.filter(value => value.id !== 2);
            filteredReport.push(secondReport)

            return filteredReport;
        }
        return report;
    }

    const rangeFilterHandler = async (dateRangeFilter: IFilters) => {
        setPending(true)
        setRangeFilter(dateRangeFilter)
        const resultReport = await fetchFirstByDateRange({filter: dateRangeFilter}, report)
        setReport(resultReport)
        setPending(false)
    }

    const secondRangeFilterHandler = async (dateRangeFilter: IFilters) => {
        setPending(true)
        setSecondRangeFilter(dateRangeFilter)
        const resultReport = await fetchSecondByDateRange({filter: dateRangeFilter}, report)
        setReport(resultReport)
        setPending(false)
    }

    function insertCityFilter(cityId: number, filter: IFilters): IFilters {
        if (filter && filter.filters.length > 0) {
            const newFilters = [...filter.filters]
            if (cityId !== 0) {
                const cityFilter = newFilters.filter(value => value.field === 'cityId');
                if (cityFilter.length === 0) {
                    const filter: IFilter = {field: 'cityId', operator: '=', value: cityId}
                    newFilters.push(filter)
                } else {
                    cityFilter[0].value = cityId
                }
                return {filters: newFilters, operator: 'and'}
            } else {
                let noCityFilter = newFilters.filter(value => value.field !== 'cityId');
                return {filters: noCityFilter, operator: 'and'}
            }
        } else {
            console.error("Incorrect input filter value for date range")
        }
        return {filters:[], operator: 'and'}
    }

    const cityFilterHandler = (cityId: number) => {
        const firstFilter = insertCityFilter(cityId, dateRangeFilter);
        setRangeFilter(firstFilter)

        const secondFilter = insertCityFilter(cityId, secondDateRangeFilter);
        setSecondRangeFilter(secondFilter)

        fetchOperations({filter: firstFilter}, {filter: secondFilter})
    }

    return (
        <>
            <Box sx={{width:'100%', display:'flex', justifyContent:'space-between', alignItems: 'end'}}>
                <Box sx={{display:'flex', alignItems: 'end', mb:1, mt:1}}>
                    <DateTimeRange fieldName={"created"} filters={dateRangeFilter} onTimeRangeChange={rangeFilterHandler}/>
                    <DateTimeRange fieldName={"created"} filters={secondDateRangeFilter} onTimeRangeChange={secondRangeFilterHandler}/>
                    <CitySelect onCityChange={cityFilterHandler} filters={dateRangeFilter}/>
                </Box>
            </Box>
            
            <CompareReportTable
                isAutoHeight={true}
                operations={report}
                isPending={isPending}
            />
        </>
    );
}

export default CompareReportByRange;