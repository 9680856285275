import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ErrorBoundary from "./pages/ErrorBoundary";
import {Provider} from "react-redux";
import {setupStore} from "./app/store";


const store = setupStore();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <ErrorBoundary>
        <Provider store={store}>
            <App />
        </Provider>

    </ErrorBoundary>

  // </React.StrictMode>
);

