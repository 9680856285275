import {config} from "../ApiConstants";
// import axios from "axios";
import {useAppDispatch} from "../hooks/appHooks";
import {AxiosError} from "axios/index";
import {setError} from "../reducers/message/globalMessageSlice";
import {IRequestQueryMultiple} from "../model/pageRequestQuery/IRequestQueryMultiple";
import {IFinReport, IFinReportPage} from "../model/finReport/IFinReport";
import axiosApi from "../axiosApi/axiosConfig";
import {ICity} from "../model/city/ICity";
import {ICitySettings} from "../model/city/ICitySettings";

export function useCityController(){
    const dispatch = useAppDispatch()

    async function getCities(): Promise<ICity[]>{
        try{
            const response = await axiosApi.get<ICity[]>(config.url.CITY + '/all')
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання міст.', message:(error.response?.data as any).message}))
            return []
        }
    }

    async function getCitySettings(id:number): Promise<ICitySettings | undefined>{
        try{
            const response = await axiosApi.get<ICitySettings>(config.url.CITY + '/settings/' + id)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання налаштувань для міста.', message:(error.response?.data as any).message}))
            return undefined
        }
    }

    async function updateCitySettings(settings: ICitySettings): Promise<ICitySettings>{
        const response = await axiosApi.patch<ICitySettings>(config.url.CITY + '/settings', settings)
        return response.data
        // try{
        //     const response = await axiosApi.patch<ICitySettings>(config.url.CITY + '/settings', settings)
        //     return response.data
        // }catch (e : unknown) {
        //     const error = e as AxiosError
        //     dispatch(setError({title:'Помилка оновлення налаштувань для міста.', message:(error.response?.data as any).message}))
        // }
    }

    return {
        getCities,
        getCitySettings,
        updateCitySettings
    }

}