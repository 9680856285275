import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { IAuthData, initialAuthState} from "../../model/auth/IAuth";
import {RootState} from "../../store";

interface IAuthState {
    state: IAuthData
}

const initialState: IAuthState = {
    state: {...initialAuthState},
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearAuth: (state) => {
            state.state.token = null
            state.state.isAuthenticated = false
            state.state.role = 'GUEST'
            state.state.permissions = []
            state.state.userId = 0
            state.state.data = undefined
        },
        setAuthData: (state, action:PayloadAction<IAuthData>) => {
            state.state = action.payload
        },
        setAuthenticated: (state, action:PayloadAction<boolean>) => {
            state.state.isAuthenticated = action.payload
        }
    }
});

export const {
    clearAuth,
    setAuthData,
    setAuthenticated,
} = authSlice.actions

export const auth = (state: RootState) => state.auth

export default authSlice.reducer;