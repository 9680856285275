import {config} from "../ApiConstants";
// import axios from "axios";
import {useAppDispatch, useAppSelector} from "../hooks/appHooks";
import {AxiosError} from "axios/index";
import {setError} from "../reducers/message/globalMessageSlice";
import {IRequestQueryMultiple} from "../model/pageRequestQuery/IRequestQueryMultiple";
import {ITopUpCardOperation, ITopUpCardOperationPage} from "../model/topUpCardOperation/ITopUpCardOperation";
import axiosApi from "../axiosApi/axiosConfig";

export function useTopUpCardOperationController(){
    const dispatch = useAppDispatch()

    async function sendOperation(operation: ITopUpCardOperation){
            try {
                await axiosApi.post(config.url.TOP_UP_CARD_OPERATION, operation)
            } catch (e : unknown) {
                const error = e as AxiosError
                dispatch(setError({title:'Помилка відправки операції.', message:(error.response?.data as any).message}))
            }
    }

    async function updateOperation(operation: ITopUpCardOperation):Promise<ITopUpCardOperation | undefined>{
        try {
            let response = await axiosApi.patch<ITopUpCardOperation>(config.url.TOP_UP_CARD_OPERATION, operation);
            return response.data
        } catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка відправки операції.', message:(error.response?.data as any).message}))
        }
    }

    async function getOperations(queryParams:IRequestQueryMultiple): Promise<ITopUpCardOperationPage>{
        try{
            const response = await axiosApi.post<ITopUpCardOperation[]>(config.url.TOP_UP_CARD_OPERATION + '/page', queryParams)
            const totalRows:number = parseInt(response.headers['amount-rows'])
            return {operations: response.data, totalRows: totalRows}
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання операцій.', message:(error.response?.data as any).message}))
            return {operations: [], totalRows: 0}
        }

    }

    return {
        sendOperation,
        updateOperation,
        getOperations,

    }

}